import { useState, useMemo, useEffect, useRef, useContext, useCallback } from 'react';
import { Box, Menu, Button, CircularProgress, Divider, MenuItem, Select, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DocumentRequestStatus, IDocumentRequest, IFile } from '../../schemas';
import { useGetDealFileZipQuery, useGetDealParticipantsQuery, useGetDealQuery, useLazyGetDealFileZipUrlQuery } from '../../features/deals-api';
import DocumentRequestRow from '../Deal/DocumentRequestRow';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Params, useLoaderData, useSearchParams } from 'react-router-dom';
import LoadingButton from '../LoadingButton';
import { DealActionContext } from '../Deal/DealDiligenceBase';
import { useGetDealDocumentRequestsQuery, useGetDocumentRequestSectionsQuery, useReorderDocumentRequestMutation, useReorderDocumentRequestSectionMutation } from '../../features/document-requests-api';
import { downloadFileFromUrl } from '../../utils/utils';
import DiligenceProgressMeter from '../Deal/DiligenceProgressMeter';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import DocumentRequestSection from '../Deal/DocumentRequestSection';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CreateDocumentRequestSectionDialog from '../Dialogs/CreateDocumentRequestSectionDialog';
import { usePopoverContext } from '../../utils/hooks';


export interface DocumentSection {
  readonly key: string;
  readonly title: string;
  readonly description?: string;
  readonly status?: DocumentRequestStatus;
  readonly files: IFile[];
  readonly documentRequest?: IDocumentRequest;
}

export default function DealDocuments(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    props: createDocumentRequestSectionDialogProps,
    showDialog: showCreateDocumentRequestSection,
  } = usePopoverContext();

  const { dealId } = useLoaderData() as Params;

  const {createDocumentRequestClicked} = useContext(DealActionContext);

  const documentRequestId = searchParams.get('documentRequestId');
  const initialFilter = searchParams.get('filter');

  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [downloadZipLoading, setDownloadZipLoading] = useState(false);
  const [openDocumentRequestId, setOpenDocumentRequestId] = useState(documentRequestId);
  const [filter, setFilter] = useState<'all' | 'my-priorities' | 'set-priorities'>(initialFilter as any ?? 'all');
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});

  const sectionElements = useRef<Record<string, HTMLElement>>({});

  const {data: deal} = useGetDealQuery({dealId: dealId!}, { skip: dealId == null });
  const {data: account} = useGetCurrentAccountQuery();
  const {data: dealFileZip} = useGetDealFileZipQuery({ dealId: deal?._id ?? '' }, { skip: deal?._id == null, pollingInterval: 15_000 });
  const {data: participants} = useGetDealParticipantsQuery({ dealId: deal?._id ?? '' }, { skip: deal?._id == null });
  const {data: documentRequestSections, isLoading: isDocumentRequestSectionsLoading} = useGetDocumentRequestSectionsQuery({ dealId: deal?._id! }, { skip: deal?._id == null, pollingInterval: account?.type === 'lender' ? 30_000 : 5_000 });
  const {data: documentRequests, isLoading: documentRequestsLoading} = useGetDealDocumentRequestsQuery({ dealId: deal?._id ?? '' }, {skip: deal?._id == null, pollingInterval: account?.type === 'lender' ? 30_000 : 5_000});
  const [getDealFileZipUrl] = useLazyGetDealFileZipUrlQuery();

  const [reorderDocumentRequestSections] = useReorderDocumentRequestSectionMutation();
  const [reorderDocumentRequests] = useReorderDocumentRequestMutation();

  const filterNames: Record<typeof filter, string> = useMemo(() => {
    return {
      'all': 'All',
      'my-priorities': 'My priority items',
      'set-priorities': 'Priority items I\'ve set',
    };
  }, []);

  const filteredDocumentRequests = useMemo(() => {
    return documentRequests?.filter(dr => {
      switch (filter) {
        case 'all':
          return true;
        case 'my-priorities':
          if (account?.type === 'sponsor') {
            return dr != null && dr.isSponsorPriority;
          } else if (account?.type === 'lender') {
            return dr != null && dr.isLenderPriority;
          }

          return false;
        case 'set-priorities':
          if (account?.type === 'sponsor') {
            return dr != null && dr.isLenderPriority;
          } else if (account?.type === 'lender') {
            return dr != null && dr.isSponsorPriority;
          }

          return false;
      }

      return false;
    });
  }, [account?.type, documentRequests, filter]);

  const documentRequestsBySection = useMemo(() => {
    return documentRequestSections?.reduce((obj, section) => {
      const docRequests = filteredDocumentRequests?.filter(dr => dr.sectionId === section._id) ?? [];
      docRequests?.sort((a, b) => a.orderIndex - b.orderIndex);

      return {
        ...obj,
        [section._id]: docRequests,
      };
    }, {} as Record<string, (IDocumentRequest & {_insertingId?: string})[]>);
  }, [documentRequestSections, filteredDocumentRequests]);

  const canDragAndDrop = account?.type === 'lender';

  const addChecklistItemClicked = useCallback(() => {
    setAddMenuAnchorEl(null);

    if (createDocumentRequestClicked != null) {
      createDocumentRequestClicked();
    }
  }, [createDocumentRequestClicked]);

  const addSectionClicked = useCallback(() => {
    setAddMenuAnchorEl(null);
    showCreateDocumentRequestSection();
  }, [showCreateDocumentRequestSection]);

  const onDocumentRequestSectionDragEnd = useCallback((result: DropResult) => {
    if (deal?._id == null) {
      return;
    }

    if (!result.destination) {
      return;
    }

    const sourceSectionId = result.source.droppableId;
    const destinationSectionId = result.destination.droppableId;

    if (sourceSectionId === 'all') {
      // Section reordering...
      reorderDocumentRequestSections({
        dealId: deal._id,
        sectionId: result.draggableId,
        orderIndex: result.destination.index,
      });
    } else {
      // Document request reordering...
      reorderDocumentRequests({
        dealId: deal._id,
        documentRequestId: result.draggableId,
        destinationSectionId: destinationSectionId,
        orderIndex: result.destination.index,
      })
    }
  }, [deal?._id, reorderDocumentRequestSections, reorderDocumentRequests]);

  const filterChanged = useCallback((value: typeof filter) => {
    setFilter(value);
    setSearchParams({
      ...searchParams,
      filter: value,
    });
  }, [setFilter, setSearchParams, searchParams]);

  const downloadDealPackageClicked = useCallback(async () => {
    if (deal?._id == null || dealFileZip == null || dealFileZip.status !== 'complete') {
      return;
    }

    setDownloadZipLoading(true);

    try {
      const {url} = await getDealFileZipUrl({ dealId: deal._id }).unwrap();
      downloadFileFromUrl(url, deal.name ?? 'project');
    } catch (error) {
      console.error(error);
      // TODO: Snack
    } finally {
      setDownloadZipLoading(false);
    }
  }, [deal, dealFileZip, getDealFileZipUrl]);

  useEffect(() => {
    if (documentRequestId != null) {
      const documentRequest = documentRequests?.find(dr => dr._id === documentRequestId);
      if (documentRequest != null) {
        setOpenDocumentRequestId(documentRequestId);
      }
    }
  }, [documentRequestId, documentRequests]);

  useEffect(() => {
    if (openDocumentRequestId != null && documentRequests != null) {
      const ref = sectionElements.current[documentRequestId!];
      if (ref != null) {
        ref.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openDocumentRequestId, documentRequests, documentRequestId, setSearchParams]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '12px',
    }}>
      <DiligenceProgressMeter dealId={dealId} />

      <Box sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: 'space-between',
        paddingTop: 2,
        paddingBottom: '4px',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '16px',
        }}>
          <Typography sx={{
            alignSelf: isMobile ? 'flex-start' : 'center',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '19.57px',
            color: '#333333',
          }}>
            {'Lender document requests'}
          </Typography>

          <Select
            sx={{
              height: '32px',
              borderRadius: '8px',
            }}
            inputProps={{
              sx: {
                paddingLeft: '16px !important',
                paddingRight: '16px !important'
              }
            }}
            renderValue={value => (
              <Typography sx={{
                justifySelf: 'center',
                fontWeight: '500',
                fontSize: '12px',
              }}>
                {`Filter by: ${filterNames[value]}`}
              </Typography>
            )}
            IconComponent={() => null}
            value={filter}
            onChange={e => filterChanged(e.target.value as any)}>
            <MenuItem value='all'>{filterNames['all']}</MenuItem>
            <MenuItem value='my-priorities'>{filterNames['my-priorities']}</MenuItem>
            <MenuItem value='set-priorities'>{filterNames['set-priorities']}</MenuItem>
          </Select>
        </Box>

        {account?.type === 'lender' && account?._id !== deal?.accountId &&
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            paddingTop: isMobile ? '12px' : 0,
          }}>
            {dealFileZip != null &&
              <Tooltip
                title={dealFileZip.status !== 'complete' ? 'Deal package is being created, please check back soon' : null}
                placement='top'
                arrow>
                <span>
                  <LoadingButton
                    sx={{height: '36px'}}
                    variant='outlined'
                    color='secondary'
                    loading={downloadZipLoading}
                    disabled={dealFileZip.status !== 'complete'}
                    onClick={downloadDealPackageClicked}>
                    <FileDownloadOutlinedIcon
                      sx={{marginRight: '10px'}}
                      fontSize='small' />
                    {'Download deal package'}
                  </LoadingButton>
                </span>
              </Tooltip>
            }

            {account?.currentSubscription?.isActive &&
              <Button
                sx={{
                  height: '36px',
                  gap: '8px',
                  paddingRight: 1,
                  paddingLeft: '16px',
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                variant='contained'
                color='secondary'
                onClick={e => setAddMenuAnchorEl(e.currentTarget)}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                  paddingRight: '16px',
                }}>
                  <AddRoundedIcon fontSize='small' />
                  <Typography sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '17.12px',
                    color: 'white',
                  }}>
                    {'Add'}
                  </Typography>
                </Box>

                <Divider
                  sx={{
                    borderColor: '#FFFFFF4D',
                  }}
                  flexItem
                  orientation='vertical' />

                <KeyboardArrowDown fontSize='small' />
              </Button>
            }
          </Box>
        }
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: '8px',
        boxShadow: '0px 0px 16px 0px #3333331F',
        background: 'white',
      }}>
        {documentRequestsLoading || isDocumentRequestSectionsLoading || documentRequestsBySection == null ? (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '60px',
          }}>
            <CircularProgress size={24} />
          </Box>
        ) : documentRequestSections != null && documentRequestSections.length === 0 ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            gap: 3,
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              fontStyle: 'italic',
              color: '#333333DD',
            }}>
              {'No checklist items have been added.'}
            </Typography>

            {account?.type === 'lender' && account?.currentSubscription?.isActive &&
              <Button
                sx={{
                  gap: '8px',
                }}
                variant='contained'
                color='secondary'
                onClick={() => createDocumentRequestClicked ? createDocumentRequestClicked() : null}>
                <AddRoundedIcon fontSize='small' />
                {'Add checklist item'}
              </Button>
            }
          </Box>
        ): (
          <DragDropContext onDragEnd={onDocumentRequestSectionDragEnd}>
            <Droppable
              droppableId='all'
              type='SECTION'>
                {(provided) => {
                  return (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}>
                      {documentRequestSections?.map((section, index) => (
                        <Draggable
                          key={section._id}
                          draggableId={section._id}
                          index={index}
                          isDragDisabled={!canDragAndDrop}>
                          {(provided) => {
                            return (
                              <Box
                                ref={provided.innerRef}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                {...provided.draggableProps}>
                                <DocumentRequestSection
                                  section={section}
                                  documentRequests={documentRequestsBySection[section._id]}
                                  canDrag={canDragAndDrop}
                                  dragHandleProps={provided.dragHandleProps}
                                  editable={canDragAndDrop}
                                  showPercentComplete={filter === 'all'}
                                  onOpenChanged={(open) => setOpenSections({...openSections, [section._id]: open})}>
                                  <Droppable
                                    droppableId={section._id}
                                    type='DOCUMENT_REQUEST'
                                    isDropDisabled={!openSections[section._id]}>
                                    {(providedDocumentRequest) => (
                                      <Box
                                        {...providedDocumentRequest.droppableProps}
                                        ref={providedDocumentRequest.innerRef}
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}>
                                        {documentRequestsBySection[section._id].map((documentRequest, drIndex) => (
                                          <Draggable
                                            key={documentRequest._id ?? documentRequest._insertingId}
                                            draggableId={documentRequest._id ?? documentRequest._insertingId ?? ''}
                                            index={drIndex}
                                            isDragDisabled={filter !== 'all' || !canDragAndDrop || documentRequestsBySection[section._id].some(dr => dr._id == null)}>
                                            {(providedDocumentRequest) => (
                                              <Box
                                                ref={providedDocumentRequest.innerRef}
                                                {...providedDocumentRequest.draggableProps}>
                                                <DocumentRequestRow
                                                  ref={(e) => {
                                                    if (e != null && documentRequest != null) {
                                                      sectionElements.current[documentRequest._id ?? ''] = e;
                                                    }
                                                  }}
                                                  editable={canDragAndDrop}
                                                  closable
                                                  canDrag={canDragAndDrop}
                                                  draggingDisabled={filter !== 'all' || documentRequestsBySection[section._id].some(dr => dr._id == null)}
                                                  dragHandleProps={providedDocumentRequest.dragHandleProps}
                                                  open={openDocumentRequestId != null && documentRequest._id === openDocumentRequestId}
                                                  deal={deal}
                                                  participants={participants}
                                                  documentRequest={documentRequest} />
                                              </Box>
                                            )}
                                          </Draggable>
                                        ))}
                                        {providedDocumentRequest.placeholder}
                                      </Box>
                                    )}
                                  </Droppable>
                                </DocumentRequestSection>
                              </Box>
                            ) as React.ReactNode;
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  ) as React.ReactNode;
                }}
            </Droppable>
          </DragDropContext>
        )}
      </Box>

      <Menu
        open={addMenuAnchorEl != null}
        anchorEl={addMenuAnchorEl}
        onClose={() => setAddMenuAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={addChecklistItemClicked}>
          <Typography variant='menuItem'>
            {'Add checklist item'}
          </Typography>
        </MenuItem>
        <MenuItem onClick={addSectionClicked}>
          <Typography variant='menuItem'>
            {'Add section'}
          </Typography>
        </MenuItem>
      </Menu>

      <CreateDocumentRequestSectionDialog
        {...createDocumentRequestSectionDialogProps}
        deal={deal} />
    </Box>
  );
}
