import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import DealBase from '../Deal/DealBase';
import DealDiligence from '../Deal/DealDiligence';
import mixpanel from 'mixpanel-browser';
import { Dialog, DialogContent, Alert, DialogActions, Button } from '@mui/material';
import { useDefaultPath } from '../../utils/hooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import PageContainer from '../PageContainer';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { useGetDealQuery } from '../../features/deals-api';


type Params = {
  dealId?: string;
  documentRequestId?: string;
}

export interface PathItem {
  readonly title: string;
  readonly path?: string;
}

export const loader = async ({ params }: { params: Object }) => {
  return params;
}

export const DealContext = createContext<{
  breadcrumbs?: PathItem[],
}>({});

export default function Deal(): JSX.Element {
  const navigate = useNavigate();
  const defaultPath = useDefaultPath();
  const { dealId } = useLoaderData() as Params;

  const [dealNotFound, setDealNotFound] = useState(false);

  const {data: account} = useGetCurrentAccountQuery();
  const {data: deal, isLoading: isDealFetching, refetch, error: getDealError} = useGetDealQuery({dealId: dealId!}, {
    pollingInterval: 5_000,
    skip: dealId == null || dealNotFound,
  });

  const breadcrumbs: PathItem[] = useMemo(() => {
    switch (account?.type) {
      case 'sponsor':
      case 'broker':
        return [
          deal?.accountId === account?._id
            ? { title: 'My Projects', path: '/deals' }
            : { title: 'Shared with Me', path: '/shared-deals' },
          { title: deal?.name ?? 'Project' },
        ];
      case 'investor':
        return [
          { title: 'Equity Marketplace', path: '/equity-deals' },
          { title: deal?.name ?? 'Project' },
        ];
      case 'lender':
        return [
          { title: 'Shared with Me', path: '/shared-deals' },
          { title: deal?.name ?? 'Project' },
        ];
      default:
        return [];
    }
  }, [deal, account]);

  useEffect(() => {
    if ((getDealError as FetchBaseQueryError)?.status === 404) {
      setDealNotFound(true);
    }
  }, [getDealError]);

  useEffect(() => {
    if (deal?._id != null) {
      mixpanel.track('view deal', {
        dealId: deal._id,
      });
    }
  }, [deal?._id]);

  if (dealNotFound) {
    return (
      <PageContainer>
        <Dialog open>
          <DialogContent>
            <Alert severity='error'>{'That project could not be found'}</Alert>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => navigate(defaultPath)}>
              {'Back'}
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    );
  }

  return (
    <DealContext.Provider value={{
      breadcrumbs,
    }}>
      {deal?.status === 'diligence' ? (
        <DealDiligence
          deal={deal}
          isDealFetching={isDealFetching}
          refetchDeal={refetch} />
      ) : (
        <DealBase
          deal={deal}
          isDealFetching={isDealFetching}
          refetchDeal={refetch} />
      )}
    </DealContext.Provider>
  );
}
