import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, Box, CircularProgress, Divider, IconButton, List, SxProps, TextField, Theme, Tooltip, Typography } from '@mui/material';
import useCustomClassesAndTheme from './useCustomClassesAndTheme';
import sxClasses from './Styles/Chat.Styles';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import { useGetChatThreadByRecipientsQuery, useGetMessageAttachmentDownloadUrlMutation, useGetThreadParticpantsQuery, useGetThreadQuery, useSendChatMessageMutation, useSetLastReadMessageForThreadMutation } from '../features/messaging-api';
import { IThread, MessageAttachment } from '../schemas';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import { useEffectDebounced, usePrevious } from '../utils/hooks';
import ChatMessage from './ChatMessage';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useLazyGetDealFileDownloadUrlQuery, useUpdateDealMutation } from '../features/deals-api';
import { useGetNotificationsQuery, useSetNotificationReadMutation } from '../features/notifications-api';
import { clearInitialChatData } from '../features/messaging-slice';
import { useAppDispatch } from '../app/hooks';
import { useGetCurrentAccountQuery, useGetCurrentUserQuery } from '../features/accounts-api';


dayjs.extend(relativeTime);
dayjs.extend(isToday);

interface Props {
  readonly sx?: SxProps<Theme>;
  readonly windowKey: string;
  readonly title?: string;
  readonly subtitle?: string;
  readonly recipientAccountIds?: string[];
  readonly identifier?: string;
  readonly threadId?: string;
  readonly dealId?: string;
  readonly defaultMessage?: string;
  readonly initialSendAction?: 'request-white-glove';
  readonly requiredPath?: string;
  readonly closable?: boolean;
  readonly minimizable?: boolean;
  readonly minimized?: boolean;
  readonly onClose?: () => void;
}

export default function Chat(props: Props): JSX.Element {

  const classes = useCustomClassesAndTheme(sxClasses);
  const dispatch = useAppDispatch();

  const [dealId] = useState<string | undefined>(props.dealId);
  const [initialSendAction] = useState<'request-white-glove' | undefined>(props.initialSendAction);
  const [thread, setThread] = useState<IThread | undefined>();
  const [currentMessage, setCurrentMessage] = useState(props.defaultMessage ?? '');
  const [minimized, setMinimized] = useState(props.minimized ?? false);

  const initialMessageSent = useRef(false);
  const messagesEnd = useRef<HTMLDivElement>(null);

  const {data: account} = useGetCurrentAccountQuery();
  const {data: user} = useGetCurrentUserQuery();
  const {data: notifications} = useGetNotificationsQuery();

  const {
    data: threadDataInitial,
    isLoading: isThreadDataInitialLoading,
    refetch: refetchThreadInitial,
  } = useGetChatThreadByRecipientsQuery({
    recipientAccountIds: [...props.recipientAccountIds ?? [], account?._id!],
    identifier: props.identifier,
  }, {
    skip: props.threadId != null || props.recipientAccountIds == null || account?._id == null
  });

  const {
    data: threadData,
    refetch: refetchThread,
    isLoading: isThreadDataLoading,
  } = useGetThreadQuery({threadId: thread?._id ?? props.threadId!}, {
    skip: props.threadId == null && thread == null,
    pollingInterval: 5000,
  });

  const {
    data: threadParticipants,
    isLoading: isThreadParticipantsLoading,
  } = useGetThreadParticpantsQuery({threadId: thread?._id ?? props.threadId!}, {
    skip: props.threadId == null && thread == null
  });

  const [getMessageAttachmentUrl] = useGetMessageAttachmentDownloadUrlMutation();
  const [getDealFileUrl] = useLazyGetDealFileDownloadUrlQuery();
  const [sendChatMessage] = useSendChatMessageMutation();
  const [setNotificationRead] = useSetNotificationReadMutation();
  const [updateDeal] = useUpdateDealMutation();
  const [setLastReadMessage] = useSetLastReadMessageForThreadMutation();

  const previousMessageCount = usePrevious(thread?.messages.length);

  const sendingAccount = useMemo(() => {
    if (threadParticipants?.find(a => a._id === account?._id)) {
      return account;
    } else if (threadParticipants?.find(a => a._id === user?.individualAccountId)) {
      return user?.individualAccount;
    }

    return undefined;
  }, [threadParticipants, user, account]);

  const recipientAccounts = useMemo(() => {
    if (user == null || threadParticipants == null) {
      return undefined;
    }

    return threadParticipants.filter(a => a._id !== sendingAccount?._id);
  }, [threadParticipants, user, sendingAccount?._id]);

  const lastMessageId = useMemo(() => {
    if (thread == null) {
      return undefined;
    }

    const filteredMessages = thread.messages; //.filter(m => m.senderAccountId === account?._id);
    const lastMessage = filteredMessages[filteredMessages.length - 1];
    return lastMessage?._id;
  }, [thread]);

  const lastReadMessageId = useMemo(() => {
    if (thread == null) {
      return undefined;
    }

    const lrm = thread.lastReadMessages?.find(l => l.accountId === account?._id);
    return lrm?.messageId;
  }, [account?._id, thread]);

  const messageAttachmentClicked = useCallback(async (attachment: MessageAttachment) => {
    if (thread == null) {
      return;
    }

    switch (attachment.type) {
      case 'thread': {
        if (thread.attachments == null) {
          return;
        }

        const attachmentData = thread.attachments[attachment.fileId];
        const {url: fileUrl} = await getMessageAttachmentUrl({
          threadId: thread._id,
          fileKey: attachmentData.key,
        }).unwrap();

        const {data: blob} = await axios.get(fileUrl, {responseType: 'blob'});
        fileDownload(blob, attachmentData.name ?? attachmentData.key);
        break;
      }

      case undefined:
      case 'deal': {
        if (attachment.dealId == null) {
          return;
        }

        const result = await getDealFileUrl({
          dealId: attachment.dealId,
          fileType: 'file',
          fileKey: attachment.key,
        }).unwrap();

        const {data: blob} = await axios.get(result.url, {responseType: 'blob'});
        fileDownload(blob, attachment.name ?? attachment.key);
        break;
      }
    }
  }, [getDealFileUrl, getMessageAttachmentUrl, thread]);

  const sendChatMessageClicked = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();

    if (thread == null || sendingAccount == null || user == null || !currentMessage) {
      return;
    }

    sendChatMessage({
      threadId: thread._id,
      message: currentMessage,
      senderAccountId: sendingAccount._id,
      senderUserId: user._id,
      senderFullName: sendingAccount.name,
      recipientAccountIds: thread.recipientIds,
      identifier: props.identifier,
      attachments: [],
    });

    setCurrentMessage('');

    if (!initialMessageSent.current && initialSendAction != null) {
      switch (initialSendAction) {
        case 'request-white-glove':
          if (dealId != null) {
            updateDeal({
              dealId: dealId,
              whiteGlove: {
                status: 'requested'
              }
            });
          }
          break;
      }

      initialMessageSent.current = true;
    }
  }, [thread, initialSendAction, updateDeal, dealId, currentMessage, sendingAccount, user, props.identifier, sendChatMessage]);

  const handleChatMessageFieldKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendChatMessageClicked(event);
    }
  }, [sendChatMessageClicked]);

  const headerClicked = useCallback(() => {
    if (minimized) {
      setMinimized(false);
    }
  }, [minimized, setMinimized]);

  const minimizeClicked = useCallback(() => {
    if (!minimized) {
      setMinimized(true);
    }
  }, [minimized, setMinimized]);

  useEffect(() => {
    dispatch(clearInitialChatData({ key: props.windowKey }));
  }, [dispatch, props.windowKey]);

  useEffect(() => {
    setThread(undefined);
    if (props.recipientAccountIds != null) {
      refetchThreadInitial();
    } else if (props.threadId != null) {
      refetchThread();
    }
  }, [props.recipientAccountIds, props.windowKey, props.identifier, props.threadId, refetchThread, refetchThreadInitial]);

  useEffect(() => {
    if (threadDataInitial != null) {
      setThread(threadDataInitial);
      messagesEnd.current?.scrollIntoView({ behavior: 'auto' });
    }
  }, [threadDataInitial]);

  useEffect(() => {
    if (threadData != null) {
      setThread(threadData);
    }
  }, [threadData]);

  useEffect(() => {
    if (thread?.messages != null && threadParticipants != null) {
      messagesEnd.current?.scrollIntoView({
        behavior: previousMessageCount == null ? 'auto' : 'smooth',
      });
    }
  }, [thread?.messages.length, threadParticipants, previousMessageCount, thread?.messages]);

  useEffect(() => {
    async function readNotifications() {
      if (!minimized) {
        (notifications ?? [])
          .filter(n => !n.isRead && n.threadId != null && n.threadId === thread?._id && (typeof n._id === 'string' || n.notificationId != null))
          .forEach(n => setNotificationRead({ notificationId: typeof n._id === 'string' ? n._id : n.notificationId ?? '', isRead: true }));
      }
    }

    readNotifications();
  }, [notifications, setNotificationRead, thread?._id, minimized]);

  useEffectDebounced(() => {
    if (thread?._id != null && lastMessageId != null && lastMessageId !== lastReadMessageId) {
      setLastReadMessage({
        threadId: thread?._id,
        messageId: lastMessageId,
      });
    }
  }, 500, [thread?._id, lastMessageId, lastReadMessageId]);

  return (
    <Box sx={{
      ...classes.root,
      ...props.sx ?? {},
      height: minimized ? undefined : '600px',
    }}>
      <Box
        sx={{
          display: 'flex',
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingTop: '22px',
          paddingBottom: '22px',
          width: '100%',
          justifyContent: 'space-between',
        }}
        onClick={headerClicked}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
          {recipientAccounts?.length === 1 &&
            <Avatar
              sx={{
                width: '36px',
                height: '36px',
              }}
              src={recipientAccounts[0].profileImageUrl}
              alt={recipientAccounts[0].name} />
          }

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <Tooltip
              arrow
              placement={'top'}
              title={recipientAccounts?.map(a => a.name).sort().join(', ')}>
              <Typography sx={{
                fontWeight: '500',
                fontSize: 16,
              }}>
                {props.title ?? recipientAccounts?.map(a => a.name).join(', ')}
              </Typography>
            </Tooltip>

            {props.subtitle &&
              <Typography sx={{
                marginTop: '4px',
                marginLeft: recipientAccounts?.length === 1 ? '10px' : undefined,
                fontWeight: '400',
                fontSize: 12,
                color: '#33333380',
              }}>
                {props.subtitle}
              </Typography>
            }
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          {props.minimizable &&
            <IconButton
              sx={{
                alignSelf: 'center',
                color: '#33333380',
              }}
              onClick={minimizeClicked}>
              {minimized ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          }

          {props.closable &&
            <IconButton
              sx={{
                alignSelf: 'center',
                color: '#33333380',
              }}
              onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          }
        </Box>
      </Box>

      {!minimized &&
        <>
          <Divider />

          <Box sx={classes.messagesBox}>
            {(isThreadDataInitialLoading || isThreadParticipantsLoading || (isThreadDataLoading && thread == null)) ? (
              <Box style={classes.loadingIndicatorBox}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              recipientAccounts != null &&
                <>
                  <List sx={classes.messagesList}>
                    {thread?.messages?.map((message, idx) => (
                      <ChatMessage
                        key={idx}
                        side={message.senderAccountId === sendingAccount?._id ? 'right' : 'left'}
                        account={message.senderAccountId === sendingAccount?._id ? sendingAccount : recipientAccounts.find(a => a._id === message.senderAccountId)}
                        message={message}
                        thread={thread}
                        attachmentClicked={messageAttachmentClicked} />
                    ))}
                  </List>

                  <div ref={messagesEnd} style={classes.messagesEnd} />
                </>
            )}
          </Box>

          <Divider />

          <Box sx={classes.footerBox}>
            <form
              style={classes.footerForm}
              onSubmit={sendChatMessageClicked}>
              <TextField
                sx={{
                  width: '100%',
                }}
                variant='standard'
                placeholder='Write a message...'
                multiline
                maxRows={4}
                autoComplete={'none'}
                value={currentMessage}
                onChange={e => setCurrentMessage(e.target.value)}
                onKeyDown={handleChatMessageFieldKeyDown}
                InputProps={{ disableUnderline: true }} />

              <IconButton
                disabled={!currentMessage}
                onClick={sendChatMessageClicked}>
                <SendIcon type='submit' />
              </IconButton>
            </form>
          </Box>
        </>
      }
    </Box>
  );
}
