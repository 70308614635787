import { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { Box, Collapse, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DocumentRequestStatusIndicator from "./DocumentRequestStatusIndicator";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { DocumentRequestStatus, IDocumentRequest, IDocumentRequestSection } from "../../schemas";
import EditableTextField from '../EditableTextField';
import { useUpdateDocumentRequestSectionMutation } from '../../features/document-requests-api';
import DeleteDocumentRequestSectionDialog from '../Dialogs/DeleteDocumentRequestSectionDialog';
import { usePopoverContext } from '../../utils/hooks';
import { DealActionContext } from './DealDiligenceBase';


export type DocumentRequetsSectionProps = PropsWithChildren<{
  section: IDocumentRequestSection;
  documentRequests: IDocumentRequest[];
  canDrag?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  editable?: boolean;
  showPercentComplete?: boolean;
  onOpenChanged?: (open: boolean) => void;
}>;

export default function DocumentRequestSection({section, documentRequests, canDrag, dragHandleProps, editable, showPercentComplete, onOpenChanged, children}: DocumentRequetsSectionProps): JSX.Element {

  const {
    props: deleteSectionDialogProps,
    showDialog: showDeleteSection,
  } = usePopoverContext();

  const {
    createDocumentRequestClicked,
  } = useContext(DealActionContext);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(section.name);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [updateDocumentRequestSection] = useUpdateDocumentRequestSectionMutation();

  const acceptedDocumentRequests = useMemo(() => {
    return documentRequests.filter(dr => dr.status === 'accepted');
  }, [documentRequests]);

  const percentComplete = useMemo(() => {
    const percent = acceptedDocumentRequests.length / documentRequests.length;
    return (percent * 100.0).toFixed(0);
  }, [acceptedDocumentRequests.length, documentRequests.length]);

  const sectionComplete = useMemo((): DocumentRequestStatus => {
    if (documentRequests.length === 0) {
      return 'pending';
    }

    return acceptedDocumentRequests.length === documentRequests.length ? 'accepted' : 'pending';
  }, [acceptedDocumentRequests.length, documentRequests.length]);

  const openClicked = useCallback(() => {
    const newOpen = !open;

    setOpen(newOpen);
    if (onOpenChanged != null) {
      onOpenChanged(newOpen);
    }
  }, [onOpenChanged, open]);

  const nameFinishedEditing = useCallback(() => {
    updateDocumentRequestSection({
      dealId: section.dealId,
      sectionId: section._id,
      name: name,
    });
  }, [name, section._id, section.dealId, updateDocumentRequestSection]);

  const moreClicked = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const addChecklistItemClicked = useCallback(() => {
    setMenuAnchorEl(null);

    if (createDocumentRequestClicked != null) {
      createDocumentRequestClicked(section._id);
    }
  }, [createDocumentRequestClicked, section._id]);

  const deleteClicked = useCallback(() => {
    setMenuAnchorEl(null);
    showDeleteSection();
  }, [showDeleteSection]);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 2,
            paddingBottom: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '8px',
            cursor: 'pointer',
            background: '#333333',
            ':hover': {
              background: '#404040',
            },
          }}
          onClick={openClicked}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1
          }}>
            {canDrag && dragHandleProps != null &&
              <Box
                sx={{
                  marginTop: '6px',
                }}
                {...dragHandleProps}>
                <DragIndicatorIcon
                  sx={{
                    fontSize: '18px',
                    color: '#cccccc',
                  }} />
              </Box>
            }

            <IconButton
              size='small'
              onClick={openClicked}>
              {open ? (
                <ExpandMoreIcon
                  sx={{
                    color: 'white',
                  }}
                 fontSize='small' />
              ) : (
                <ChevronRightIcon
                  sx={{
                    color: 'white',
                  }}
                  fontSize='small' />
              )}
            </IconButton>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}>
              <DocumentRequestStatusIndicator
                status={sectionComplete}
                colorMode='dark' />

              <EditableTextField
                editable={editable}
                typographyProps={{
                  sx: {
                    fontWeight: '500',
                    fontSize: '14px',
                    color: 'white',
                  },
                }}
                textFieldProps={{
                  size: 'small',
                  InputProps: {
                    sx: {
                      fontWeight: '500',
                      fontSize: '14px',
                      color: 'white',
                    }
                  },
                }}
                value={name}
                onChange={v => setName(v ?? '')}
                onStartEditing={() => null}
                onFinishEditing={nameFinishedEditing} />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}>
            {documentRequests.length > 0 && showPercentComplete !== false &&
              <Box sx={{
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingTop: '6px',
                paddingBottom: '6px',
                borderRadius: '30px',
                borderWidth: '2px',
                borderColor: '#eef4f1',
                borderStyle: 'solid',
              }}>
                <Typography sx={{
                  fontWeight: '500',
                  fontSize: '13px',
                  lineHeight: '15.9px',
                  color: '#eef4f1',
                }}>
                  {`${percentComplete}% complete`}
                </Typography>
              </Box>
            }

            {editable &&
              <IconButton
                sx={{
                  width: '28px',
                  height: '28px',
                  background: 'transparent',
                }}
                color='info'
                onClick={moreClicked}>
                <MoreHorizIcon sx={{
                  fontSize: '18px',
                  color: 'white',
                }} />
              </IconButton>
            }
          </Box>
        </Box>

        <Collapse in={open}>
          {children}
        </Collapse>
      </Box>

      <Menu
        open={menuAnchorEl != null}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={addChecklistItemClicked}>
          <AddRoundedIcon
            sx={{color: '#33333380'}}
            fontSize='small' />
          <Typography variant='menuItem'>
            {'Add checklist item'}
          </Typography>
        </MenuItem>
        <Box>
          <Divider component='li' />

          <MenuItem onClick={deleteClicked}>
            <DeleteOutlinedIcon
              sx={{color: '#33333380'}}
              fontSize='small' />
            <Typography variant='menuItem'>
              {'Delete'}
            </Typography>
          </MenuItem>
        </Box>
      </Menu>

      <DeleteDocumentRequestSectionDialog
        {...deleteSectionDialogProps}
        section={section} />
    </>
  );
}
