import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery, baseUrls } from "./apiHelper";
import { DocumentRequestStatus, IAccount, IDocumentRequest, IDocumentRequestSection } from '../schemas';
import * as Sentry from '@sentry/react';


export const documentRequestsApiSlice = createApi({
  reducerPath: 'api-document-requests',
  tagTypes: ['DocumentRequest', 'DocumentRequestSection'],
  baseQuery: createBaseQuery(baseUrls.documentRequests),
  endpoints: (build) => ({
    getDealDocumentRequests: build.query<(IDocumentRequest & {_insertingId?: string})[], {dealId: string}>({
      providesTags: (documentRequests) => documentRequests?.map(dr => ({ type: 'DocumentRequest', id: dr._id })) ?? [],
      query: ({dealId}) => `/deals/${dealId}/document-requests`,
    }),
    getDealDocumentRequest: build.query<IDocumentRequest, {dealId: string, documentRequestId: string}>({
      providesTags: (_dr, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId}) => `/deals/${dealId}/document-requests/${documentRequestId}`,
    }),
    getDocumentRequestAttachmentUrl: build.query<{url: string}, {dealId: string, documentRequestId: string, fileKey: string}>({
      query: ({dealId, documentRequestId, fileKey}) => `/deals/${dealId}/document-requests/${documentRequestId}/attachments/${fileKey}`,
    }),
    getDocumentRequestFileUrl: build.query<{url: string}, {dealId: string, documentRequestId: string, fileKey: string}>({
      query: ({dealId, documentRequestId, fileKey}) => `/deals/${dealId}/document-requests/${documentRequestId}/submitted/${fileKey}`,
    }),
    getDocumentRequestSections: build.query<IDocumentRequestSection[], {dealId: string}>({
      providesTags: ['DocumentRequestSection'],
      query: ({dealId}) => `/deals/${encodeURIComponent(dealId)}/document-request-sections`,
    }),

    createDealDocumentRequest: build.mutation<{documentRequest: IDocumentRequest, message: string}, {
      dealId: string,
      name: string,
      description: string,
      sectionId?: string,
      section?: {
        name: string,
      },
    }>({
      invalidatesTags: ['DocumentRequest', 'DocumentRequestSection'],
      query: ({dealId, name, description, sectionId, section}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests`,
          method: 'POST',
          body: {
            name,
            description,
            sectionId,
            section,
          }
        };
      },
    }),
    createDealDocumentRequestOptimistic: build.mutation<{documentRequest: IDocumentRequest, message: string}, {
      dealId: string,
      name?: string,
      description?: string,
      sectionId: string,
      preceedingDocumentRequestId?: string,
    }>({
      query: ({dealId, name, description, sectionId, preceedingDocumentRequestId}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests`,
          method: 'POST',
          body: {
            name: name ?? 'New Item',
            description: description ?? 'Item description',
            sectionId,
            preceedingDocumentRequestId,
          }
        };
      },
      onQueryStarted: async ({dealId, name, description, sectionId, preceedingDocumentRequestId}, {dispatch, queryFulfilled}) => {
        const insertingId = `${Math.random()}`;

        const patchResult = dispatch(
          documentRequestsApiSlice.util.updateQueryData('getDealDocumentRequests', {dealId}, (documentRequests) => {
            if (preceedingDocumentRequestId != null) {
              const preceedingIndex = documentRequests.findIndex(dr => dr._id === preceedingDocumentRequestId);
              if (preceedingIndex < 0) {
                return;
              }

              // Increment all document requests after the new one
              documentRequests.forEach(dr => {
                if (dr.orderIndex > documentRequests[preceedingIndex].orderIndex) {
                  dr.orderIndex++;
                }
              });

              // Insert the new document request
              documentRequests.splice(preceedingIndex + 1, 0, {
                _insertingId: insertingId,
                dealId: dealId,
                sectionId: sectionId,
                orderIndex: documentRequests[preceedingIndex].orderIndex + 1,
                name: name ?? 'New Item',
                description: description ?? 'Item description',
                status: 'pending',
                files: [],
                createdByAccountId: '',
                createdByUserId: '',
                createdDt: new Date().toISOString(),
              });
            } else {
              const orderIndex = documentRequests.length > 0
                ? documentRequests[documentRequests.length - 1].orderIndex + 1
                : 0;

              documentRequests.push({
                _insertingId: insertingId,
                dealId: dealId,
                sectionId: sectionId,
                orderIndex: orderIndex,
                name: name ?? 'New Item',
                description: description ?? 'Item description',
                status: 'pending',
                files: [],
                createdByAccountId: '',
                createdByUserId: '',
                createdDt: new Date().toISOString(),
              })
            }
          })
        );

        try {
          const result = await queryFulfilled;

          dispatch(documentRequestsApiSlice.util.updateQueryData('getDealDocumentRequests', {dealId}, (documentRequests) => {
            const index = documentRequests.findIndex(dr => dr._insertingId === insertingId);
            if (index < 0) {
              return;
            }

            documentRequests[index] = {...result.data.documentRequest};
          }));
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          patchResult.undo();
        }
      }
    }),
    deleteDealDocumentRequest: build.mutation<{message: string}, {dealId: string, documentRequestId: string}>({
      invalidatesTags: ['DocumentRequest'],
      query: ({dealId, documentRequestId}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}`,
          method: 'DELETE',
        };
      },
    }),
    updateDealDocumentRequest: build.mutation<{message: string}, {dealId: string, documentRequestId: string, changes: Partial<IDocumentRequest>}>({
      invalidatesTags: (_r, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId, changes}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}`,
          method: 'PATCH',
          body: changes,
        };
      },
    }),
    updateDealDocumentRequestOptimistic: build.mutation<{message: string, status: string}, {dealId: string, documentRequestId: string, changes: Partial<IDocumentRequest>}>({
      query: ({dealId, documentRequestId, changes}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}`,
          method: 'PATCH',
          body: changes,
        };
      },
      onQueryStarted: async ({dealId, documentRequestId, changes}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          documentRequestsApiSlice.util.updateQueryData('getDealDocumentRequests', {dealId}, (documentRequests) => {
            const index = documentRequests.findIndex(dr => dr._id === documentRequestId);
            if (index  < 0) {
              return;
            }

            documentRequests[index] = {
              ...documentRequests[index],
              ...changes,
            };
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          patchResult.undo();
        }
      }
    }),
    setDealDocumentRequestStatus: build.mutation<any, {dealId: string, documentRequestId: string, status: DocumentRequestStatus, rejectionReason?: string}>({
      invalidatesTags: (_r, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId, status, rejectionReason}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/status`,
          method: 'PATCH',
          body: {
            status: status,
            rejectionReason: rejectionReason,
          }
        };
      },
    }),
    getDealDocumentRequestAttachmentUploadUrl: build.mutation<{url: string}, {dealId: string, documentRequestId: string, fileName: string, contentType: string}>({
      query: ({dealId, documentRequestId, fileName, contentType}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/attachments/upload`,
          method: 'POST',
          body: {
            fileName: fileName,
            contentType: contentType,
          },
        };
      },
    }),
    getDealDocumentRequestUploadUrl: build.mutation<{url: string}, {dealId: string, documentRequestId: string, fileName: string, contentType: string}>({
      query: ({dealId, documentRequestId, fileName, contentType}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/submitted/upload`,
          method: 'POST',
          body: {
            fileName: fileName,
            contentType: contentType,
          },
        };
      },
    }),
    deleteDocumentRequestFile: build.mutation<any, {dealId: string, documentRequestId: string, fileKey: string}>({
      query: ({dealId, documentRequestId, fileKey}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/submitted/${encodeURIComponent(fileKey)}`,
          method: 'DELETE',
        };
      },
      onQueryStarted: async ({dealId, documentRequestId, fileKey}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          documentRequestsApiSlice.util.updateQueryData('getDealDocumentRequests', {dealId}, (draft) => {
            const index = draft.findIndex(docReq => docReq._id === documentRequestId);
            draft[index] = {
              ...draft[index],
              files: draft[index].files.filter(f => f.key !== fileKey),
            }
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          patchResult.undo();
        }
      }
    }),
    addDocumentRequestEvent: build.mutation<{message: string}, {dealId: string, documentRequestId: string, message: string, senderAccount: IAccount}>({
      invalidatesTags: (_r, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId, message}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/events`,
          method: 'POST',
          body: {
            message: message,
          }
        };
      },
    }),
    createDocumentRequestSection: build.mutation<{ message: string, status: string }, {dealId: string, name: string}>({
      invalidatesTags: ['DocumentRequestSection', 'DocumentRequest'],
      query: ({dealId, name}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-request-sections`,
          method: 'POST',
          body: {
            name,
          }
        };
      }
    }),
    updateDocumentRequestSection: build.mutation<{message: string, status: string}, {
      dealId: string,
      sectionId: string,
      name?: string,
    }>({
      query: ({dealId, sectionId, name}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-request-sections/${encodeURIComponent(sectionId)}`,
          method: 'PATCH',
          body: {
            name,
          }
        };
      },
      onQueryStarted: async ({dealId, sectionId, name}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          documentRequestsApiSlice.util.updateQueryData('getDocumentRequestSections', {dealId}, (sections) => {
            const index = sections.findIndex(s => s._id === sectionId);
            if (index < 0) {
              return;
            }

            if (name != null) {
              sections[index].name = name;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          patchResult.undo();
        }
      }
    }),
    deleteDocumentRequestSection: build.mutation<{message: string, status: string}, {
      dealId: string,
      sectionId: string,
    }>({
      invalidatesTags: ['DocumentRequestSection'],
      query: ({dealId, sectionId}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-request-sections/${encodeURIComponent(sectionId)}`,
          method: 'DELETE',
        };
      }
    }),
    reorderDocumentRequestSection: build.mutation<{message: string, status: string}, {dealId: string, sectionId: string, orderIndex: number }>({
      query: ({dealId, sectionId, orderIndex}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-request-sections/${encodeURIComponent(sectionId)}/order`,
          method: 'PATCH',
          body: {
            orderIndex,
          }
        };
      },
      onQueryStarted: async ({dealId, sectionId, orderIndex}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          documentRequestsApiSlice.util.updateQueryData('getDocumentRequestSections', { dealId }, (sections) => {
            const section = sections.find(s => s._id === sectionId);

            if (!section) {
              // Section not found, no changes
              return sections;
            }

            const oldIndex = section.orderIndex;
            const newIndex = orderIndex;

            // If no change in position, return sections unchanged
            if (oldIndex === newIndex) {
              return sections;
            }

            // Update orderIndex values for all affected sections
            sections.forEach(s => {
              if (s._id === sectionId) {
                // Update the section being moved
                s.orderIndex = newIndex;
              } else if (oldIndex < newIndex && s.orderIndex > oldIndex && s.orderIndex <= newIndex) {
                // If moving section down (to a higher index)
                // Decrement indices of sections between old and new position
                s.orderIndex--;
              } else if (oldIndex > newIndex && s.orderIndex >= newIndex && s.orderIndex < oldIndex) {
                // If moving section up (to a lower index)
                // Increment indices of sections between new and old position
                s.orderIndex++;
              }
            });

            // Sort sections based on their updated orderIndex values
            sections.sort((a, b) => a.orderIndex - b.orderIndex);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          patchResult.undo();
        }
      }
    }),
    reorderDocumentRequest: build.mutation<{message: string, status: string}, {
      dealId: string,
      documentRequestId: string,
      destinationSectionId: string,
      orderIndex: number,
    }>({
      query: ({ dealId, documentRequestId, destinationSectionId, orderIndex }) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/order`,
          method: 'PATCH',
          body: {
            destinationSectionId,
            orderIndex,
          }
        };
      },
      onQueryStarted: async ({ dealId, documentRequestId, destinationSectionId, orderIndex }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          documentRequestsApiSlice.util.updateQueryData('getDealDocumentRequests', { dealId }, (documentRequests) => {
            const documentRequest = documentRequests.find(dr => dr._id === documentRequestId);
            if (!documentRequest) return documentRequests;

            const sourceSectionId = documentRequest.sectionId;
            const sourceOrderIndex = documentRequest.orderIndex;

            // If moving within the same section
            if (destinationSectionId === sourceSectionId) {
              documentRequests.forEach(dr => {
                if (dr.sectionId !== sourceSectionId) {
                  return;
                }

                // Only adjust items in the affected section
                if (dr._id === documentRequestId) {
                  // Update the moved item
                  dr.orderIndex = orderIndex;
                } else if (sourceOrderIndex < orderIndex) {
                  // Moving down (to a higher index)
                  if (dr.orderIndex > sourceOrderIndex && dr.orderIndex <= orderIndex) {
                    dr.orderIndex--;
                  }
                } else if (sourceOrderIndex > orderIndex) {
                  // Moving up (to a lower index)
                  if (dr.orderIndex >= orderIndex && dr.orderIndex < sourceOrderIndex) {
                    dr.orderIndex++;
                  }
                }
              });
            }
            // If moving to a different section
            else {
              documentRequests.forEach(dr => {
                if (dr._id === documentRequestId) {
                  // Update the moved item with new section and order
                  dr.sectionId = destinationSectionId;
                  dr.orderIndex = orderIndex;
                }
                // Update items in the source section
                else if (dr.sectionId === sourceSectionId && dr.orderIndex > sourceOrderIndex) {
                  // Decrement order for items that were after the moved item in source section
                  dr.orderIndex--;
                }
                // Update items in the destination section
                else if (dr.sectionId === destinationSectionId && dr.orderIndex >= orderIndex) {
                  // Increment order for items at or after the insertion point in destination section
                  dr.orderIndex++;
                }
              });
            }

            return documentRequests;
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          patchResult.undo();
        }
      }
    })
  })
});

export const {
  useGetDealDocumentRequestsQuery,
  useGetDealDocumentRequestQuery,
  useLazyGetDocumentRequestAttachmentUrlQuery,
  useLazyGetDocumentRequestFileUrlQuery,
  useGetDocumentRequestSectionsQuery,

  useCreateDealDocumentRequestMutation,
  useCreateDealDocumentRequestOptimisticMutation,
  useDeleteDealDocumentRequestMutation,
  useUpdateDealDocumentRequestMutation,
  useUpdateDealDocumentRequestOptimisticMutation,
  useSetDealDocumentRequestStatusMutation,
  useGetDealDocumentRequestAttachmentUploadUrlMutation,
  useGetDealDocumentRequestUploadUrlMutation,
  useDeleteDocumentRequestFileMutation,
  useAddDocumentRequestEventMutation,
  useCreateDocumentRequestSectionMutation,
  useUpdateDocumentRequestSectionMutation,
  useDeleteDocumentRequestSectionMutation,
  useReorderDocumentRequestSectionMutation,
  useReorderDocumentRequestMutation,
} = documentRequestsApiSlice;
