import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, Divider, Tooltip, Typography } from '@mui/material';
import { IAccount, IDocumentRequest } from '../../schemas';
import { useGetDealParticipantsQuery, useGetDealQuery } from '../../features/deals-api';
import LoadingButton from '../LoadingButton';
import { useUpdateDealDocumentRequestMutation } from '../../features/document-requests-api';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import CREEDDialogTitle from '../CREEDDialogTitle';



interface Props extends DialogProps {
  readonly documentRequest?: IDocumentRequest;
}

export default function DocumentRequestAccessDialog({documentRequest, ...dialogProps}: Props): JSX.Element {

  const [loading, setLoading] = useState(false);
  const [enabledAccounts, setEnabledAccounts] = useState<Record<string, boolean>>({});

  const {data: account} = useGetCurrentAccountQuery();
  const {data: deal} = useGetDealQuery({dealId: documentRequest?.dealId!}, { skip: documentRequest?.dealId == null });
  const {data: dealParticipants} = useGetDealParticipantsQuery({ dealId: documentRequest?.dealId ?? '' }, { skip: documentRequest == null });

  const [updateDocumentRequest] = useUpdateDealDocumentRequestMutation();

  const rows = useMemo(() => {
    return (dealParticipants ?? []).filter(a => a._id !== account?._id);
  }, [account, dealParticipants]);

  const rowsWithAccess = useMemo(() => {
    if (documentRequest == null) {
      return [];
    }

    return rows.filter(a => enabledAccounts[a._id] && a._id !== documentRequest.createdByAccountId && a._id !== deal?.accountId)
  }, [rows, enabledAccounts, deal?.accountId, documentRequest]);

  const switchChanged = useCallback((account: IAccount, value: boolean) => {
    setEnabledAccounts({
      ...enabledAccounts,
      [account._id]: value,
    })
  }, [setEnabledAccounts, enabledAccounts]);

  const cancelClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [dialogProps]);

  const confirmClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (documentRequest == null || documentRequest._id == null) {
      return;
    }

    setLoading(true);

    try {
      await updateDocumentRequest({
        dealId: documentRequest.dealId,
        documentRequestId: documentRequest._id,
        changes: {
          confidentialAccess: rowsWithAccess.map(a => a._id),
        }
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [documentRequest, rowsWithAccess, dialogProps, updateDocumentRequest, setLoading]);

  useLayoutEffect(() => {
    if (documentRequest != null) {
      setEnabledAccounts((documentRequest.confidentialAccess ?? []).reduce((obj, accountId) => {
        return {
          ...obj,
          [accountId]: true,
        };
      }, {}));
    }
  }, [documentRequest, setEnabledAccounts]);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Manage Access'
        subtitle='Choose which organizations will have access to this item'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />

      <Divider />

      <DialogContent>
        {rows.map(row => (
          <Box
            key={row._id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '10px',
              paddingBottom: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Box sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <Avatar
                sx={{
                  width: '40px',
                  height: '40px',
                }}
                src={row.profileImageUrl} />

              <Typography sx={{
                paddingLeft: '12px',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
              }}>
                {row.name}
              </Typography>
            </Box>

            <Tooltip
              arrow
              title={
                documentRequest?.createdByAccountId === row._id
                  ? 'This organization or user created this item and will always have access to it'
                  : deal?.accountId === row._id
                    ? 'This organization or user created the project and will always have access to it'
                    : null
              }
              placement='right'>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
                <Checkbox
                  checked={deal?.accountId === row._id || documentRequest?.createdByAccountId === row._id ? true : enabledAccounts[row._id]}
                  onChange={(e, checked) => switchChanged(row, checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={documentRequest == null || loading || deal?.accountId === row._id || documentRequest?.createdByAccountId === row._id} />

                <Typography sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                    opacity: deal?.accountId === row._id || documentRequest?.createdByAccountId === row._id || enabledAccounts[row._id] ? 1.0 : 0.0,
                  }}>
                    {'Can access'}
                  </Typography>
              </Box>
            </Tooltip>
          </Box>
        ))}
      </DialogContent>

      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          disabled={loading}
          onClick={cancelClicked}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={loading}
          onClick={confirmClicked}>
          {'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
