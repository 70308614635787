import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import sxClasses from '../Styles/ResetPwd.Styles';
import useCustomClassesAndTheme from '../useCustomClassesAndTheme';
import PageContainer from '../PageContainer';
import LoadingButton from '../LoadingButton';
import * as Sentry from '@sentry/react';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import { useNavigate } from 'react-router-dom';
import { passwordConformsToPolicy } from '../../utils/utils';
import PasswordField from '../PasswordField';


export default function ResetPwd() {

  const classes = useCustomClassesAndTheme(sxClasses);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [resetState, setResetState] = useState({
    emailSent: false,
    verificationCode: '',
    done: false
  });
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  function validateForm() {
    return email.length > 0;
  }

  const handleReset = async(event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      setResetLoading(true);

      const res = await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      console.log('reset pwd res: ', res);

      setResetState({
        ...resetState,
        done: true
      });

      // setSuccess('Your password has be reset successfully.');
      setError('');

      dispatch(snacked({
        message: 'Your password has been reset successfully',
        severity: 'success',
      }));

      navigate('/login');
    } catch (e: any) {
      Sentry.captureException(e);
      setResetState({
        ...resetState,
        done: false
      });
      console.error(e);
      setError(e.message);
    } finally {
      setResetLoading(false);
    }
  }

  const handleSubmit = async(event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      setSubmitLoading(true);

      const res = await Auth.forgotPassword(email);
      setError('');
      console.log('forgotPassword res: ', res);

      setResetState({
        ...resetState,
        emailSent: true
      });
      setError('');
      setSuccess('Verification code sent to your email. Make sure to check your spam or newsletter folders.');
    } catch (e: any) {
      Sentry.captureException(e);
      console.error(e);
      setError(e.message);
      setResetState({
        ...resetState,
        emailSent: false
      });
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <PageContainer>
      {
        !resetState.emailSent
        ? <form onSubmit={handleSubmit}>
            <Grid container style={{marginTop: 100}}>
              <Grid item xs={12} sx={classes.centerCtr}>
                <TextField
                  style={{width: 300}}
                  required
                  id='email'
                  type='email'
                  label='Your email address'
                  value={email}
                  onChange={e => setEmail(e.target.value.toLowerCase())}
                />
              </Grid>
              <Grid sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }} item xs={12}>
                <LoadingButton
                  variant='contained'
                  type='submit'
                  disabled={!validateForm() && !resetState.emailSent}
                  loading={submitLoading}
                  style={{width: 300}}>
                  {'Send confirmation code'}
                </LoadingButton>
                {
                  resetState.emailSent
                  ? <Typography variant='body1'>{'Verification code sent to your email. Make sure to check your spam or newsletter folders.'}</Typography>
                  : null
                }

                {
                  error !== ''
                  ? <div>{error}</div>
                  : null
                }
              </Grid>
            </Grid>
          </form>
        : <form onSubmit={handleReset}>
            <Grid container style={{marginTop: 100}}>
              <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <TextField
                  style={{width: 300, marginTop: 8}}
                  required
                  id='code'
                  type='string'
                  label='Verification code (received by email)'
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value.trim())} />
                <PasswordField
                  style={{width: 300, marginTop: 8}}
                  required
                  id='password'
                  type='password'
                  label='New password'
                  inputProps={{
                    minLength: 8,
                  }}
                  error={newPassword.length > 0 && !passwordConformsToPolicy(newPassword)}
                  helperText={'Must be at least 8 characters'}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)} />
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <LoadingButton
                  sx={{
                    width: '300px',
                  }}
                  variant='contained'
                  type='submit'
                  disabled={!validateForm() && !resetState.emailSent}
                  loading={resetLoading}>
                  {'Reset Password'}
                </LoadingButton>
                {
                  resetState.emailSent
                  ? <Typography variant='body1'>{success}</Typography>
                  : null
                }

                {
                  error !== ''
                  ? <div>{error}</div>
                  : null
                }
              </Grid>
            </Grid>
          </form>
      }
    </PageContainer>
  );
};
