import React, { useCallback, useMemo, useState } from 'react';
import PageContainer from '../PageContainer';
import { Box, Divider, MenuItem, Select, TextField, Typography } from '@mui/material';
import Card from '../Card';
import LoadingButton from '../LoadingButton';
import { useGetCurrentAccountTeamInvitationsQuery, useGetCurrentAccountTeamQuery, useInviteUserToTeamMutation } from '../../features/accounts-api';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import RemoveAccessDialog from '../TeamMembers/RemoveAccessDialog';
import TeamMemberRow from '../TeamMembers/TeamMemberRow';


export default function TeamMembers(): JSX.Element {

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [accessLevel, setAccessLevel] = useState<'member' | 'member-confidential'>('member');
  const [removeAccessOpen, setRemoveAccessOpen] = useState(false);

  const {data: team} = useGetCurrentAccountTeamQuery(undefined, { pollingInterval: 30_000 });
  const {data: invitations} = useGetCurrentAccountTeamInvitationsQuery(undefined, { pollingInterval: 30_000 });

  const [inviteUserToTeam] = useInviteUserToTeamMutation();

  const items = useMemo(() => {
    return [
      ...(team?.users ?? []).map(u => {
        return {
          key: u._id,
          user: u,
          invitation: undefined,
          createdDt: team?.userInfo[u._id].joinedDt ?? u.createdDt ?? team?.createdDt!,
        };
      }),
      ...(invitations ?? []).map(inv => {
        return {
          key: inv._id,
          invitation: inv,
          user: undefined,
          createdDt: inv.createdDt,
        };
      }),
    ]
    .sort((a, b) => Date.parse(a.createdDt) - Date.parse(b.createdDt));
  }, [team, invitations]);

  const [selectedTeamMember, setSelectedTeamMember] = useState<typeof items[number] | undefined>();

  const sendInviteClicked = useCallback(async () => {
    setLoading(true);

    try {
      await inviteUserToTeam({
        email: email,
        accessLevel: accessLevel,
      }).unwrap();

      setEmail('');
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed sending invitation',
        severity: 'error',
      }));
    } finally {
      setLoading(false);
    }
  }, [email, accessLevel, setLoading, dispatch, inviteUserToTeam]);

  const copyLinkClicked = useCallback(async (item: typeof items[number]) => {
    if (item.invitation == null) {
      return;
    }

    const url = `${window.location.protocol}//${window.location.host}/accept-team-invitation/${item.invitation._id}`;
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy', true, url);
    }

    dispatch(snacked({
      message: 'Link copied to clipboard',
      severity: 'info',
    }));
  }, [dispatch]);

  const removeAccessClicked = useCallback((item: typeof items[number]) => {
    setSelectedTeamMember(item);
    setRemoveAccessOpen(true);
  }, [setSelectedTeamMember, setRemoveAccessOpen]);

  return (
    <PageContainer>
      <Typography sx={{
        fontWeight: '400',
        fontSize: '28px',
        lineHeight: '34.24px',
      }}>
        {'Team members'}
      </Typography>

      <Card sx={{
        marginTop: '48px',
        paddingTop: '32px',
        paddingBottom: '32px',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '19.57px',
        }}>
          {'Invite new members'}
        </Typography>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          marginTop: '24px',
        }}>
          <Box sx={{
            display: 'flex',
            flex: 1,
            height: '44px',
            alignItems: 'center',
            borderRadius: '8px',
            background: '#3333330A',
          }}>
            <TextField
              sx={{
                flex: 1,
                fontWeight: '400',
                fontSize: '14px',
                '& fieldset': { border: 'none' },
              }}
              placeholder='Enter an email'
              disabled={loading}
              value={email}
              onChange={(e) => setEmail(e.target.value)} />
          </Box>

          <Select
            sx={{
              height: '44px',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              borderRadius: '8px',
              background: '#3333330A',
            }}
            value={accessLevel}
            onChange={e => setAccessLevel(e.target.value as typeof accessLevel)}>
            <MenuItem value={'member'}>{'Member'}</MenuItem>
            <MenuItem value={'member-confidential'}>{'Member w/ Confidential Access'}</MenuItem>
          </Select>

          <LoadingButton
            variant='contained'
            color='secondary'
            loading={loading}
            disabled={email.length === 0}
            onClick={sendInviteClicked}>
            {'Send invite'}
          </LoadingButton>
        </Box>
      </Card>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '40px',
        paddingBottom: '14.5px',
      }}>
        <Typography sx={{
          flex: 1,
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '14.68px',
          color: '#33333380',
        }}>
          {'USER'}
        </Typography>

        <Typography sx={{
          flex: 1,
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '14.68px',
          color: '#33333380',
        }}>
          {'ACCESS LEVEL'}
        </Typography>

        <Box sx={{ flex: 0.16 }} />
      </Box>

      <Divider sx={{background: '#33333314'}} />

      {items.map((item, index) => (
        <>
          <TeamMemberRow
            team={team}
            user={item.user}
            invitation={item.invitation}
            removeAccessClicked={() => removeAccessClicked(item)}
            copyLinkClicked={() => copyLinkClicked(item)} />

          {index < items.length - 1 &&
            <Divider sx={{
              paddingTop: 0,
              paddingBottom: 0,
              background: '#33333314',
            }} />
          }
        </>
      ))}

      <RemoveAccessDialog
        open={removeAccessOpen}
        onClose={() => setRemoveAccessOpen(false)}
        team={team}
        user={selectedTeamMember?.user}
        invitation={selectedTeamMember?.invitation} />
    </PageContainer>
  );
}
