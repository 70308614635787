import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useCreateDocumentRequestSectionMutation } from "../../features/document-requests-api";
import { IDeal } from "../../schemas";
import CREEDDialogTitle from "../CREEDDialogTitle";
import LoadingButton from "../LoadingButton";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../app/hooks";
import { snacked } from "../../features/snackMessage-slice";


export interface CreateDocumentRequestSectionDialogProps extends DialogProps {
  deal?: IDeal;
}

export default function CreateDocumentRequestSectionDialog({deal, ...dialogProps}: CreateDocumentRequestSectionDialogProps): JSX.Element {

  const dispatch = useAppDispatch();

  const [name, setName] = useState('');

  const [createSection, { isLoading: isCreateSectionLoading }] = useCreateDocumentRequestSectionMutation();

  const createSectionClicked = useCallback(async () => {
    if (deal?._id == null) {
      return;
    }

    try {
      await createSection({
        dealId: deal._id,
        name: name,
      });

      if (dialogProps.onClose != null) {
        dialogProps.onClose({}, 'escapeKeyDown');
      }

      dispatch(snacked({
        message: 'Section created',
        severity: 'success',
      }));
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed creating section',
        severity: 'error',
      }));
    }
  }, [createSection, deal?._id, dialogProps, dispatch, name])

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='New Section'
        closeClicked={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null} />

      <DialogContent>
        <Box sx={{
          paddingTop: 1,
        }}>
          <TextField
            id='name'
            label='Name'
            required
            fullWidth
            size='small'
            value={name}
            onChange={e => setName(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isCreateSectionLoading}
          onClick={createSectionClicked}>
          {'Create Section'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
