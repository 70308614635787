import { Box, Dialog, DialogContent, DialogProps, Typography } from "@mui/material";
import CREEDDialogTitle from "../CREEDDialogTitle";
import LoadingButton from "../LoadingButton";
import { useChangeSubscriptionMutation } from "../../features/payments-api";
import { useCallback, useState } from "react";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../app/hooks";
import { snacked } from "../../features/snackMessage-slice";
import CheckIcon from '@mui/icons-material/Check';
import { accountsApiSlice } from "../../features/accounts-api";
import { brokerSubscriptionFeatures } from "../../types/SubscriptionFeatures";
import { lendersApiSlice } from "../../features/lenders-api";
import { dealsApiSlice } from "../../features/deals-api";


interface Props extends DialogProps {

}

export default function UpgradeSubscriptionDialog({...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [changeSubscription] = useChangeSubscriptionMutation();

  const purchaseClicked = useCallback(async () => {
    try {
      setLoading(true);

      await changeSubscription({
        product: 'broker-subscription-pro',
      }).unwrap();

      setTimeout(() => {
        setLoading(false);

        dispatch(accountsApiSlice.util.invalidateTags(['Account']));
        dispatch(lendersApiSlice.util.invalidateTags(['LenderMetadata']));
        dispatch(dealsApiSlice.util.invalidateTags(['SearchResults']));

        dispatch(snacked({
          message: 'Upgraded to CREED Professional',
          severity: 'success',
        }));

        if (dialogProps.onClose != null) {
          dialogProps.onClose({}, 'escapeKeyDown');
        }
      }, 5000);
    } catch (error) {
      setLoading(false);
      Sentry.captureException(error);
      dispatch(snacked({
        message: 'Failed upgrading subscription, please contact us a support@creed.tech',
        severity: 'error',
      }))
    }
  }, [changeSubscription, dialogProps, dispatch]);

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Upgrade to CREED Professional'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />

      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}>
        <Box sx={{
          paddingTop: 3,
          paddingBottom: 1,
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
            {brokerSubscriptionFeatures.filter(l => l.pro).map(line => (
              <Box
                key={line.feature}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                  color: '#333333DD',
                }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '20px',
                  height: '20px',
                  borderRadius: '10px',
                  background: 'rgba(67, 151, 117, 0.2)',
                }}>
                  <CheckIcon sx={{
                    fontSize: '16px',
                    color: 'rgb(67, 151, 117)',
                  }} />
                </Box>

                <Typography sx={{
                  lineHeight: 1,
                }}>
                  {line.feature}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          flex: 1,
          alignItems: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingBottom: '24px',
          borderRadius: '8px',
          background: 'linear-gradient(135deg, rgb(221, 207, 178), rgb(217, 179, 97))',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '40px',
              lineHeight: 1,
            }}>
              {'$299'}
            </Typography>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '18px',
              opacity: 0.6,
              color: 'rgb(51, 51, 51)',
            }}>
              {'/month'}
            </Typography>
          </Box>

          <LoadingButton
            sx={{
              flex: 1,
              width: '100%',
            }}
            variant='contained'
            color='secondary'
            loading={loading}
            onClick={purchaseClicked}>
            {'Upgrade'}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
