import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { INotification } from "../schemas";
import { baseUrls, createBaseQuery } from "./apiHelper";
import * as Sentry from '@sentry/react';


export const notificationsApiSlice = createApi({
  reducerPath: 'api-notifications',
  tagTypes: ['Notification'],
  baseQuery: createBaseQuery(baseUrls.main),
  endpoints: (build) => ({
    getNotifications: build.query<INotification[], void>({
      providesTags: ['Notification'],
      query: () => '/notifications',
    }),
    setNotificationRead: build.mutation<any, {notificationId: string, isRead: boolean}>({
      query: ({notificationId, isRead}) => {
        return {
          url: `/notifications/${encodeURIComponent(notificationId)}`,
          method: 'PUT',
          body: {
            isRead,
          }
        };
      },
      onQueryStarted: async ({notificationId, isRead}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          notificationsApiSlice.util.updateQueryData('getNotifications', undefined, (draft) => {
            const index = draft.findIndex(n => n.notificationId === notificationId);
            if (index < 0) {
              return;
            }

            draft[index] = {
              ...draft[index],
              isRead: isRead,
            };
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          Sentry.captureException(error);
          patchResult.undo();
        }
      }
    }),
  })
});


export const {
  useGetNotificationsQuery,

  useSetNotificationReadMutation,
} = notificationsApiSlice;
