import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from "@mui/material";
import { useEquityFeeStructure } from "../../utils/hooks";
import { AccountEquityFeeStructure, AccountType } from "../../schemas";
import { useMemo } from "react";


interface Props {
  title?: string;
  feeStructure?: AccountEquityFeeStructure;
  accountType?: AccountType;
  accountId?: string;
}

export default function EquityFeeStructureTableSponsorBroker({title, feeStructure, accountType, accountId}: Props): JSX.Element {

  const { tiers } = useEquityFeeStructure(undefined, feeStructure, accountType, accountId);

  const feeStructureSimple = useMemo(() => {
    switch (accountType) {
      case 'sponsor': {
        // Hack for specific customer...
        if (accountId === '67b3b5542363e442dab280a7') {
          return 'Fixed 1%';
        }

        return 'Fixed 2%';
      }
      case 'broker':
        return 'Fixed 0.25%';
      default:
        return '-';
    }
  }, [accountId, accountType]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Typography sx={{
        fontSize: '14px',
        padding: '8px',
        borderRadius: '8px',
        background: '#33333311',
      }}>
        {title ?? 'CREED Equity Fee Structure'}
      </Typography>

      {(feeStructure ?? 'legacy') === 'legacy' ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{'Tier'}</TableCell>
                <TableCell>{'Financing Amount Range'}</TableCell>
                <TableCell>{'Fee Amount'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tiers.map(tier => (
                <TableRow key={tier.name}>
                  <TableCell>{tier.name}</TableCell>
                  <TableCell>{tier.range}</TableCell>
                  <TableCell>{tier.feeAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : feeStructure === 'simple' ? (
        <Box sx={{
          paddingTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}>
          <Typography sx={{
            fontWeight: 500,
            fontSize: '22px',
          }}>
            {feeStructureSimple}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
