import { useCallback, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { IDocumentRequest } from '../../schemas';
import { useUpdateDealDocumentRequestMutation } from '../../features/document-requests-api';
import LoadingButton from '../LoadingButton';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import CREEDDialogTitle from '../CREEDDialogTitle';


interface Props extends DialogProps {
  readonly documentRequest?: IDocumentRequest;
}

export default function MakeDocumentRequestConfidentialDialog({documentRequest, ...dialogProps}: Props): JSX.Element {

  const [loading, setLoading] = useState(false);

  const {data: account} = useGetCurrentAccountQuery();

  const [updateDocumentRequest] = useUpdateDealDocumentRequestMutation();

  const confirmationMessage = useMemo(() => {
    switch (account?.type) {
      case 'sponsor':
        if (documentRequest?.isLenderPriority) {
          return 'This item will be removed from the lender\'s "My Priorities" list on the project dashboard.';
        } else {
          return 'This item will be added to the lender\'s "My Priorities" list on the project dashboard.';
        }
      case 'lender':
        if (documentRequest?.isSponsorPriority) {
          return 'This item will be removed from the sponsor\'s "My Priorities" list on the project dashboard.';
        } else {
          return 'This item will be added to the sponsor\'s "My Priorities" list on the project dashboard.';
        }
      default:
        return '';
    }
  }, [account, documentRequest]);

  const confirmClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (account == null || documentRequest == null || documentRequest._id == null) {
      return;
    }

    setLoading(true);

    try {
      const changes: Partial<IDocumentRequest> = account.type === 'sponsor'
        ? { isLenderPriority: !Boolean(documentRequest.isLenderPriority) }
        : { isSponsorPriority: !Boolean(documentRequest.isSponsorPriority) };

      await updateDocumentRequest({
        dealId: documentRequest.dealId,
        documentRequestId: documentRequest._id,
        changes: changes,
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [account, documentRequest, setLoading, updateDocumentRequest, dialogProps]);

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Are you sure?'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          {confirmationMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          disabled={loading}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={loading}
          onClick={confirmClicked}>
          {'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
