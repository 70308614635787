import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { IDocumentRequest } from '../../schemas';
import LoadingButton from '../LoadingButton';
import { useDeleteDealDocumentRequestMutation } from '../../features/document-requests-api';
import CREEDDialogTitle from '../CREEDDialogTitle';


interface Props extends DialogProps {
  readonly documentRequest?: IDocumentRequest;
}

export default function DeleteDocumentRequestDialog({documentRequest, ...dialogProps}: Props): JSX.Element {

  const [loading, setLoading] = useState(false);

  const [deleteDocumentRequest] = useDeleteDealDocumentRequestMutation();

  const deleteClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (documentRequest == null || documentRequest._id == null) {
      return;
    }

    setLoading(true);

    try {
      await deleteDocumentRequest({
        dealId: documentRequest.dealId,
        documentRequestId: documentRequest._id,
      }).unwrap();
    } finally {
      setLoading(false);
    }

    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [deleteDocumentRequest, dialogProps, documentRequest]);

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Delete Document Request'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />

      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to delete ${documentRequest?.name ?? ''}?`}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={loading}
          onClick={deleteClicked}>
          {'Delete'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
