import {  useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IDeal, IDealContactedLender, IDealQuote, IDealQuoteOption, IDealQuoteTerms } from '../../../schemas';
import { Avatar, Box, Button, Tooltip, Typography, capitalize } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useCreateQuoteOptionFileUploadUrlMutation, useLazyGetDealFileDownloadUrlQuery, useReorderLenderQuotesMutation, useSearchLendersQuery } from '../../../features/deals-api';
import { useDealQuoteTermFields } from '../../../types/DealQuoteTermFields';
import { downloadFileFromUrl, formatNumericString, uploadFileToUrl } from '../../../utils/utils';
import dayjs from 'dayjs';
import { DataGridPro, GridColumnOrderChangeParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { useAppDispatch } from '../../../app/hooks';
import { snacked } from '../../../features/snackMessage-slice';
import { usePopoverContext } from '../../../utils/hooks';
import SponsorRecordQuoteDialog from '../Dialogs/SponsorRecordQuoteDialog';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { useDropzone } from 'react-dropzone';
import { useGetCurrentAccountQuery } from '../../../features/accounts-api';


const QuoteMatrixGridToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarDensitySelector />
    <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
  </GridToolbarContainer>
);

interface Props {
  deal?: IDeal;
}

export default function LenderSearchQuoteMatrixDataGrid({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {
    props: recordLenderQuoteDialogProps,
    showDialog: showRecordLenderQuote,
    currentData: recordLenderQuoteOption,
  } = usePopoverContext<{lenderName: string, optionId: string}>();

  const {
    fields,
    computeFieldValue,
  } = useDealQuoteTermFields();

  const [showScrollIndicators, setShowScrollIndicators] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<'start' | 'middle' | 'end'>('start');

  const gridRef = useRef<HTMLDivElement | null>(null);
  const lenderFileUploadRef = useRef<{lender: IDealContactedLender, option: IDealQuoteOption}>();

  const {data: account} = useGetCurrentAccountQuery();
  const {data: contactedLenders, isLoading: isLoadingContactedLenders} = useSearchLendersQuery({ dealId: deal?._id!, filter: 'contacted' }, { skip: deal?._id == null });

  const [reorderLenders] = useReorderLenderQuotesMutation();
  const [getFileDownloadUrl] = useLazyGetDealFileDownloadUrlQuery();
  const [createFileUploadUrl] = useCreateQuoteOptionFileUploadUrlMutation();

  const {
    getInputProps,
    open: openTermSheetFileDialog,
  } = useDropzone({
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (deal?._id == null || lenderFileUploadRef.current == null) {
        return;
      }

      const file = acceptedFiles[0];
      if (file == null) {
        return;
      }

      try {
        const result = await createFileUploadUrl({
          dealId: deal._id,
          lenderName: lenderFileUploadRef.current.lender.name,
          optionId: lenderFileUploadRef.current.option._id,
          fileType: 'term-sheet',
          fileName: file.name,
          contentType: file.type,
        }).unwrap();

        await uploadFileToUrl(result.url, file);

        dispatch(snacked({
          message: 'Term sheet uploaded',
          severity: 'success',
        }));
      } catch (error) {
        console.error(error);
        dispatch(snacked({
          message: 'Failed uploading term sheet',
          severity: 'error',
        }));
      }
    }
  });

  const downloadTermSheetClicked = useCallback(async (lender: IDealContactedLender, option: IDealQuoteOption) => {
    if (deal?._id == null) {
      return;
    }

    try {
      const result = await getFileDownloadUrl({
        dealId: deal._id,
        lenderName: lender.name,
        optionId: option._id,
        fileType: 'quote-option-term-sheet',
      }).unwrap();

      downloadFileFromUrl(result.url, lender.termSheet?.name);
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed downloading file',
        severity: 'error',
      }));
    }
  }, [deal?._id, getFileDownloadUrl, dispatch]);

  const editQuoteClicked = useCallback((lender: IDealContactedLender, option: IDealQuoteOption) => {
    showRecordLenderQuote({lenderName: lender.name, optionId: option._id});
  }, [showRecordLenderQuote]);

  const uploadTermSheetClicked = useCallback((lender: IDealContactedLender, option: IDealQuoteOption) => {
    lenderFileUploadRef.current = {lender, option};
    openTermSheetFileDialog();
  }, [openTermSheetFileDialog]);

  const lendersWithLatestQuotes = useMemo(() => {
    if (deal?.contactedLenders == null) {
      return [];
    }

    const unorderedObjs = deal?.contactedLenders
      .filter(lender => lender.quoteOptions != null && lender.quoteOptions.length > 0)
      .flatMap(lender => {
        return lender.quoteOptions!.map(option => {
          const latestQuote = option.quotes?.reduce((latest, quote) => {
            if (latest == null) {
              return quote;
            }

            return Date.parse(quote.createdDt) > Date.parse(latest.createdDt) ? quote : latest;
          }, undefined as IDealQuote | undefined)!;

          return {
            lender,
            option,
            latestQuote,
          };
        });
      })
      .filter(obj => obj.latestQuote != null) ?? [];

    const orderedObjs = (deal?.quoteOptionOrdering ?? [])
      .map(id => unorderedObjs.find(obj => obj.option._id === id))
      .filter(obj => obj != null) as typeof unorderedObjs[number][];

    const remainingObjs = unorderedObjs.filter(obj1 => !orderedObjs.some(obj2 => obj1.option._id === obj2.option._id));

    return [
      ...orderedObjs,
      ...remainingObjs,
    ];
  }, [deal?.contactedLenders, deal?.quoteOptionOrdering]);

  const rows = useMemo(() => {
    if (lendersWithLatestQuotes.length === 0) {
      return [];
    }

    const termsRows = fields.map(field => {
      if (field.hiddenInMatrix) {
        return null;
      }

      const lenderData = lendersWithLatestQuotes.reduce((obj, {option, latestQuote}) => {
        let rawValue = latestQuote.terms[field.key];
        if (field.type === 'computed' && field.compute != null) {
          rawValue = computeFieldValue(field, latestQuote.terms as any);
        }

        if (rawValue != null) {
          switch (field.type) {
            case 'text':
            case 'number':
            case 'computed': {
              let formattedValue = `${rawValue}`;
              switch (field.formatting) {
                case 'comma-separated':
                  formattedValue = formatNumericString(formattedValue) ?? '';
                  break;
                default:
                  break;
              }

              if (field.prefix) {
                formattedValue = `${field.prefix}${formattedValue}`;
              }

              if (field.suffix) {
                formattedValue = `${formattedValue}${field.suffix}`;
              }

              return {
                ...obj,
                [option._id!]: formattedValue,
              };
            }
            case 'select': {
              if (field.optionFormatter != null) {
                return {
                  ...obj,
                  [option._id!]: field.optionFormatter(rawValue as string),
                };
              }

              return {
                ...obj,
                [option._id]: `${rawValue}`,
              };
            }
          }
        } else {
          return {
            ...obj,
            [option._id]: 'TBD',
          };
        }

        return obj;
      }, {});

      return {
        _id: field.key,
        field: field.title,
        type: 'text',
        ...lenderData,
      };
    })
    .filter(r => r != null) as ({
        _id: keyof IDealQuoteTerms;
        field: string;
        type: string;
      })[];

    return [
      {
        _id: '_lenderName',
        field: 'Lender Name',
        type: 'text',
        ...lendersWithLatestQuotes.reduce((obj, {lender, option}) => {
          return {
            ...obj,
            [option._id]: lender.name,
          };
        }, {})
      },
      {
        _id: '_quoteType',
        field: 'Quote Type',
        type: 'text',
        ...lendersWithLatestQuotes.reduce((obj, {option, latestQuote}) => {
          return {
            ...obj,
            [option._id]: capitalize(latestQuote.type),
          };
        }, {})
      },
      {
        _id: '_lenderType',
        field: 'Lender Type',
        type: 'text',
        ...lendersWithLatestQuotes.reduce((obj, {lender, option, latestQuote}) => {
          const refLender = contactedLenders?.find(l => l.Lender === lender.name);
          return {
            ...obj,
            [option._id]: refLender != null && refLender['Lending Institution'] != null ? refLender['Lending Institution'] : latestQuote.terms.lenderType ?? undefined,
          };
        }, {}),
      },
      ...termsRows,
      {
        _id: '_termSheet',
        field: 'Term Sheet',
        type: 'term-sheet',
        ...lendersWithLatestQuotes.reduce((obj, {option}) => {
          return {
            ...obj,
            [option._id]: option.termSheet?.name,
          };
        }, {}),
      },
      {
        _id: '_submittedAt',
        field: 'Submitted At',
        type: 'text',
        ...lendersWithLatestQuotes.reduce((obj, {option, latestQuote}) => {
          return {
            ...obj,
            [option._id]: dayjs(latestQuote.createdDt).format('MMM D, YYYY')
          };
        }, {}),
      },
    ]
  }, [lendersWithLatestQuotes, fields, computeFieldValue, contactedLenders]);

  const columns: GridColDef[] = useMemo(() => {
    const lenderColumns: GridColDef<typeof rows[number]>[] = (lendersWithLatestQuotes ?? []).map(({lender, option}) => {
      return {
        field: option._id,
        headerName: lender.name,
        minWidth: 200,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          switch (params.row.type) {
            case 'text':
              return (
                <Tooltip
                  title={params.formattedValue}
                  enterDelay={1000}>
                  <span style={{
                    color: params.formattedValue === 'TBD' ? '#33333377' : undefined,
                  }}>
                    {params.formattedValue}
                  </span>
                </Tooltip>
              );
            case 'term-sheet':
              if (params.value == null) {
                return undefined;
              }

              return (
                <Tooltip
                  title={lender.termSheet?.name}
                  enterDelay={500}
                  arrow>
                  <Button
                    onClick={() => downloadTermSheetClicked(lender, option)}>
                    {'Download'}
                  </Button>
                </Tooltip>
              );
          }
        },
        renderHeader: () => {
          const url = contactedLenders?.find(l => l.Lender === lender.name)?.LogoUrl as string;

          return (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '100%',
              cursor: deal?.whiteGlove?.status !== 'enabled' || (deal.whiteGlove?.status === 'enabled' && account?.isAdmin) ? 'pointer' : undefined,
            }}>
              {url != null ? (
                <Avatar
                  sx={{
                    width: '100px',
                    height: '80px',
                  }}
                  variant='rounded'
                  src={url}
                  alt={lender.name} />
              ) : (
                <Typography sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                }}>
                  {lender.name}
                </Typography>
              )}
              {(deal?.whiteGlove?.status !== 'enabled' || (deal.whiteGlove?.status === 'enabled' && account?.isAdmin)) &&
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignSelf: 'flex-start',
                  justifySelf: 'flex-start',
                }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignSelf: 'flex-start',
                      justifySelf: 'flex-start',
                      padding: '2px',
                      borderRadius: '8px',
                      '&:hover': {
                        background: '#33333322',
                      }
                    }}
                    onClick={() => editQuoteClicked(lender, option)}>
                    <Tooltip
                      title='Edit quote'
                      enterDelay={500}
                      arrow>
                      <EditNoteRoundedIcon />
                    </Tooltip>
                  </Box>
                  <input {...getInputProps()} />

                  <Box
                    sx={{
                      display: 'flex',
                      alignSelf: 'flex-start',
                      justifySelf: 'flex-start',
                      padding: '2px',
                      borderRadius: '8px',
                      '&:hover': {
                        background: '#33333322',
                      }
                    }}
                    onClick={() => uploadTermSheetClicked(lender, option)}>
                    <Tooltip
                      title='Upload term sheet'
                      enterDelay={500}
                      arrow>
                      <FileUploadRoundedIcon />
                    </Tooltip>
                  </Box>
                </Box>
              }
            </Box>
          );
        },
      };
    });

    return [
      {
        field: 'field',
        headerName: '',
        width: 190,
        sortable: false,
        renderCell: (params) => (
          <strong>
            {params.formattedValue}
          </strong>
        )
      },
      ...lenderColumns,
    ];
  }, [lendersWithLatestQuotes, downloadTermSheetClicked, contactedLenders, deal?.whiteGlove?.status, account?.isAdmin, getInputProps, editQuoteClicked, uploadTermSheetClicked]);

  const handleScroll = useCallback((event: Event) => {
    const target = event.target as HTMLDivElement;
    const { scrollLeft, clientWidth, scrollWidth } = target;

    if (scrollLeft <= scrollWidth * 0.05) {
      setScrollPosition('start');
    } else if (scrollLeft + clientWidth >= scrollWidth * 0.95) {
      setScrollPosition('end');
    } else {
      setScrollPosition('middle');
    }
  }, []);

  const moreRightClicked = useCallback(() => {
    const gridElement = gridRef.current;
    if (gridElement == null) {
      return;
    }

    const gridViewport = gridElement.querySelector('.MuiDataGrid-virtualScroller') as HTMLDivElement;

    gridViewport.scroll({
      behavior: 'smooth',
      left: 100000,
    })
  }, []);

  const moreLeftClicked = useCallback(() => {
    const gridElement = gridRef.current;
    if (gridElement == null) {
      return;
    }

    const gridViewport = gridElement.querySelector('.MuiDataGrid-virtualScroller') as HTMLDivElement;

    gridViewport.scroll({
      behavior: 'smooth',
      left: 0,
    })
  }, []);

  const onColumnOrderChange = useCallback((params: GridColumnOrderChangeParams) => {
    if (deal?._id == null) {
      return;
    }

    const optionIds = lendersWithLatestQuotes.map(obj => obj.option._id);
    const row = optionIds.splice(params.oldIndex - 1, 1)[0];
    optionIds.splice(params.targetIndex - 1, 0, row);

    reorderLenders({
      dealId: deal._id,
      orderedOptionIds: optionIds,
    });
  }, [deal?._id, lendersWithLatestQuotes, reorderLenders]);

  const pinnedRows = useMemo(() => {
    const lenderNameRow = rows.find(r => r != null && r._id === '_lenderName');

    const pr = [lenderNameRow].filter(r => r != null);
    if (pr.length === 0) {
      return undefined;
    }

    return pr;
  }, [rows]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (deal == null || contactedLenders == null) {
      return;
    }

    const gridElement = gridRef.current;
    if (gridElement == null) {
      return;
    }

    const gridViewport = gridElement.querySelector('.MuiDataGrid-virtualScroller') as HTMLDivElement;
    if (gridViewport == null) {
      return;
    }

    if (gridViewport.scrollWidth * 0.95 > gridViewport.clientWidth) {
      setShowScrollIndicators(true);
    } else {
      setShowScrollIndicators(false);
    }
  });

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement == null) {
      return;
    }

    const gridViewport = gridElement.querySelector('.MuiDataGrid-virtualScroller') as HTMLDivElement;
    if (gridViewport == null) {
      return;
    }

    gridViewport.addEventListener('scroll', handleScroll);

    return () => {
      gridViewport.removeEventListener('scroll', handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef.current, handleScroll]);

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {showScrollIndicators &&
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 1,
            paddingBottom: 1,
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              paddingLeft: 1,
              paddingRight: 1,
              paddingTop: 0.5,
              paddingBottom: 0.5,
              cursor: 'pointer',
              borderRadius: '8px',
              background: scrollPosition === 'start' ? '#333333AA' : '#333333'
            }}
            onClick={moreLeftClicked}>
              {scrollPosition !== 'start' &&
                <WestRoundedIcon sx={{
                  fontSize: '14px',
                  color: 'white',
                }} />
              }
              <Typography sx={{
                fontWeight: '500',
                fontSize: '14px',
                color: 'white',
              }}>
                {scrollPosition !== 'start' ? 'More' : 'Done'}
              </Typography>
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              paddingLeft: 1,
              paddingRight: 1,
              paddingTop: 0.5,
              paddingBottom: 0.5,
              cursor: 'pointer',
              borderRadius: '8px',
              background: scrollPosition === 'end' ? '#333333AA' : '#333333',
            }}
            onClick={moreRightClicked}>
              <Typography sx={{
                fontWeight: '500',
                fontSize: '14px',
                color: 'white',
              }}>
                {scrollPosition !== 'end' ? 'More' : 'Done'}
              </Typography>
              {scrollPosition !== 'end' &&
                <EastRoundedIcon sx={{
                  fontSize: '14px',
                  color: 'white',
                }} />
              }
            </Box>
          </Box>
        }
        <Box sx={{
          height: '90vh',
        }}>
          <DataGridPro
            ref={r => gridRef.current = r}
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeader': {
                outline: 'none !important',
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              }
            }}
            columns={columns}
            rows={rows}
            getRowId={r => r._id}
            columnHeaderHeight={deal?.whiteGlove?.status === 'enabled' && !account?.isAdmin ? 100 : 140}
            autoPageSize
            disableColumnSelector
            disableColumnFilter
            disableColumnMenu
            hideFooter
            hideFooterPagination
            disableColumnReorder={deal?.whiteGlove?.status === 'enabled' && !account?.isAdmin}
            onColumnOrderChange={onColumnOrderChange}
            loading={isLoadingContactedLenders}
            isRowSelectable={() => false}
            pinnedRows={{
              top: pinnedRows,
            }}
            initialState={{
              pinnedColumns: {
                left: ['field'],
              },
            }}
            slots={{
              toolbar: QuoteMatrixGridToolbar,
              noRowsOverlay: () => (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '22px',
                }}>
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                  }}>
                    {'No quotes received yet, please try again later.'}
                  </Typography>
                </Box>
              ),
            }} />
        </Box>
      </Box>

      <SponsorRecordQuoteDialog
        {...recordLenderQuoteDialogProps}
        deal={deal}
        lenderName={recordLenderQuoteOption?.lenderName}
        optionId={recordLenderQuoteOption?.optionId} />
    </>
  );
}
