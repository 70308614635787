import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { IDocumentRequest } from '../../schemas';
import LoadingButton from '../LoadingButton';
import CREEDDialogTitle from '../CREEDDialogTitle';
import { useSetDealDocumentRequestStatusMutation } from '../../features/document-requests-api';
import * as Sentry from '@sentry/react';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';


interface Props extends DialogProps {
  documentRequest?: IDocumentRequest;
  onAcceptClicked?: () => Promise<void>;
}

export default function UnapproveDocumentRequestDialog({documentRequest, onAcceptClicked, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [setDocumentRequestStatus, {isLoading: isSetStatusLoading}] = useSetDealDocumentRequestStatusMutation();

  const unapproveClicked = useCallback(async () => {
    if (documentRequest == null || documentRequest._id == null) {
      return;
    }

    try {
      await setDocumentRequestStatus({
        documentRequestId: documentRequest._id,
        dealId: documentRequest.dealId,
        status: 'pending',
      }).unwrap();

      dispatch(snacked({
        message: 'Files un-approved',
        severity: 'success',
      }));

      if (dialogProps.onClose != null) {
        dialogProps.onClose({}, 'escapeKeyDown');
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed un-approving document request',
        severity: 'error',
      }));
    }

    if (dialogProps.onClose != null) {
      dialogProps.onClose({}, 'escapeKeyDown');
    }
  }, [documentRequest, dialogProps, setDocumentRequestStatus, dispatch]);

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Un-approve Document Request'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to un-approve the document request "${documentRequest?.name}" with ${documentRequest?.files.length} file(s)? This will set it back to a pending state so that the project owner can modify it.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={(e) => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isSetStatusLoading}
          onClick={unapproveClicked}>
          {'Un-approve'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
