import { Box } from '@mui/material';
import { DocumentRequestStatus } from '../../schemas';
import DoneIcon from '@mui/icons-material/Done';


interface Props {
  status: DocumentRequestStatus;
  colorMode?: 'light' | 'dark';
}

export default function DocumentRequestStatusIndicator({status, colorMode}: Props): JSX.Element {

  switch (status) {
    case 'pending':
    case 'submitted':
    case 'rejected':
      return (
        <Box sx={{
          width: '20px',
          height: '20px',
          borderRadius: '10px',
          borderWidth: '2px',
          borderColor: colorMode === 'dark' ? 'white' : '#33333333',
          borderStyle: 'solid',
        }} />
      );
    case 'accepted':
      return (
        <Box sx={{
          display: 'flex',
          width: '20px',
          height: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          borderWidth: '2px',
          borderColor: '#439775',
          borderStyle: 'solid',
          color: 'white',
          background: '#439775',
        }}>
          <DoneIcon sx={{
            fontSize: '12px',
          }} />
        </Box>
      );
  }
}
