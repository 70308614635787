import { useState, useRef } from 'react';
import { Typography, TextField, ClickAwayListener, TypographyProps, TextFieldProps } from '@mui/material';


export interface EditableTextFieldProps {
  editable?: boolean;
  value?: string;
  onChange?: (value?: string) => void;
  typographyProps: TypographyProps;
  textFieldProps: TextFieldProps;
  onStartEditing: () => void;
  onFinishEditing: () => void;
}

export default function EditableTextField({editable, value, onChange, typographyProps, textFieldProps, onStartEditing, onFinishEditing}: EditableTextFieldProps): JSX.Element {

  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState(value);
  const inputRef = useRef(null);

  const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (editable) {
      e.stopPropagation();

      setIsEditing(true);
      onStartEditing();
      // Focus the text field after it renders
      setTimeout(() => {
        if (inputRef.current) {
          (inputRef.current as any).focus();
        }
      }, 0);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    // Only trigger onChange if the value actually changed
    if (textValue !== value && onChange != null) {
      onChange(textValue);
    }
    onFinishEditing();
  };

  const handleChange = (e: any) => {
    setTextValue(e.target.value);
    if (onChange != null) {
      onChange(e.target.value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      (inputRef.current as any).blur(); // Trigger blur on Enter key
    } else if (e.key === 'Escape') {
      setTextValue(value); // Reset to original value
      (inputRef.current as any).blur();
    }
  };

  return (
    <ClickAwayListener onClickAway={isEditing ? handleBlur : () => {}}>
      <div>
        {editable && isEditing ? (
          <TextField
            inputRef={inputRef}
            value={textValue}
            onChange={handleChange}
            onClick={e => e.stopPropagation()}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            variant="outlined"
            size="small"
            fullWidth
            sx={{ my: -1 }} // Compensate for TextField padding
            {...textFieldProps} />
        ) : (
          <Typography
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 1,
                px: 1,
                mx: -1
              },
              minHeight: '1.5em', // Prevent layout shift
              ...typographyProps.sx
            }}
            {...typographyProps}>
            {value}
          </Typography>
        )}
      </div>
    </ClickAwayListener>
  );
};
