import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogProps, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import LoadingButton from '../LoadingButton';
import { IDeal } from '../../schemas';
import FileUploadBox from '../FileUploadBox';
import axios from 'axios';
import { useCreateDealDocumentRequestMutation, useGetDealDocumentRequestAttachmentUploadUrlMutation, useGetDocumentRequestSectionsQuery } from '../../features/document-requests-api';
import CREEDDialogTitle from '../CREEDDialogTitle';
import { usePrevious } from '../../utils/hooks';
import * as Sentry from '@sentry/react';


interface Props extends DialogProps {
  deal?: IDeal;
  sectionId?: string;
}

export default function CreateDocumentRequestDialog({deal, sectionId, ...dialogProps}: Props): JSX.Element {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [section, setSection] = useState<'NEW' | string>('NEW');
  const [sectionName, setSectionName] = useState<string>();
  const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const { data: documentRequestSections, isLoading: isDocumentRequestSectionsLoading } = useGetDocumentRequestSectionsQuery({dealId: deal?._id!}, {skip: deal?._id == null});

  const [createDocumentRequest] = useCreateDealDocumentRequestMutation();
  const [getAttachmentUploadUrl] = useGetDealDocumentRequestAttachmentUploadUrlMutation();

  const previousSections = usePrevious(documentRequestSections);

  const filesDropped = useCallback(async (files: File[]) => {
    setAttachmentFiles([...attachmentFiles, ...files]);
  }, [setAttachmentFiles, attachmentFiles]);

  const fileDeleted = useCallback((file: File) => {
    setAttachmentFiles(attachmentFiles.filter(f => f !== file));
  }, [setAttachmentFiles, attachmentFiles]);

  const formSubmitted = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deal?._id == null) {
      return;
    }

    setLoading(true);

    try {
      const sectionParams = section === 'NEW'
        ? {section: {name: sectionName ?? ''}}
        : {sectionId: section};

      // Create the document request
      const {documentRequest} = await createDocumentRequest({
        dealId: deal._id,
        name: name,
        description: description,
        ...sectionParams,
      }).unwrap();

      // Upload any files to the new document request
      const fileUploadUrlResponses = await Promise.all(attachmentFiles.map(f => getAttachmentUploadUrl({
        dealId: deal?._id!,
        documentRequestId: documentRequest._id!,
        contentType: f.type,
        fileName: f.name,
      }).unwrap()));

      await Promise.all(fileUploadUrlResponses.map((response, index) => {
        if (!response.url) {
          return null;
        }

        const file = attachmentFiles[index];
        return axios.put(response.url, file, {
          headers: {
            'Content-Type': file.type
          },
        });
      }));

      setName('');
      setDescription('');
      setAttachmentFiles([]);

      if (dialogProps.onClose) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [deal?._id, section, sectionName, createDocumentRequest, name, description, attachmentFiles, dialogProps, getAttachmentUploadUrl]);

  useEffect(() => {
    if (documentRequestSections != null) {
      if (documentRequestSections.length === 0) {
        setSection('NEW');
      } else if (sectionId != null) {
        setSection(sectionId);
      } else {
        const sectionsCopy = [...documentRequestSections];
        sectionsCopy.sort((a, b) => Date.parse(b.createdDt) - Date.parse(a.createdDt));

        const latestSection = sectionsCopy[0];

        setSection(latestSection._id);
      }
    }
  }, [documentRequestSections, previousSections, sectionId]);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='New Checklist Item'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />

      <form onSubmit={formSubmitted}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}>
          <FormControl
            fullWidth
            required
            disabled={isDocumentRequestSectionsLoading}
            size='small'>
            <InputLabel id='select-label'>{'Section'}</InputLabel>
            <Select
              labelId='section-label'
              label='Section'
              value={section}
              onChange={e => setSection(e.target.value)}>
              {documentRequestSections?.map((section, index) => (
                <MenuItem
                  key={section._id}
                  value={section._id}
                  divider={index === documentRequestSections.length - 1}>
                  <Typography variant='menuItem'>
                    {section.name}
                  </Typography>
                </MenuItem>
              ))}

              <MenuItem
                sx={{
                  alignItems: 'center',
                }}
                value='NEW'>
                <Typography
                  sx={{
                    fontWeight: 'bold'
                  }}
                  variant='menuItem'>
                  {'Create new section'}
                </Typography>
              </MenuItem>
            </Select>
            <FormHelperText>
              {'The section under which this checklist item should be placed. It can be moved later.'}
            </FormHelperText>
          </FormControl>

          {section === 'NEW' &&
            <TextField
              size='small'
              required
              label='Section Name'
              helperText='The name of the new section that will be created.'
              value={sectionName}
              onChange={e => setSectionName(e.target.value)} />
          }

          <TextField
            id='name'
            label='Name'
            required
            size='small'
            disabled={loading}
            helperText='The name of the checklist item.'
            value={name}
            onChange={e => setName(e.target.value)} />

          <TextField
            id='description'
            label='Description'
            size='small'
            multiline
            required
            disabled={loading}
            helperText='The kinds of files that should be uploaded to this checklist item.'
            minRows={5}
            maxRows={10}
            value={description}
            onChange={e => setDescription(e.target.value)} />

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
            }}>
              {'Attachments'}
            </Typography>

            {attachmentFiles.length > 0 &&
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '8px',
              }}>
                {attachmentFiles.map((attachment, index) => (
                  <Chip
                    key={`${attachment.name}${index}`}
                    size='small'
                    label={attachment.name}
                    onDelete={() => fileDeleted(attachment)} />
                ))}
              </Box>
            }

            <FileUploadBox
              disabled={loading}
              onFilesDropped={filesDropped} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            disabled={loading}
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Cancel'}
          </Button>

          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            loading={loading}>
            {'Create Checklist Item'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
