import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { MuiTelInput } from 'mui-tel-input';
import React, { useCallback, useMemo, useState } from 'react';
import { AccountType } from '../schemas';
import LoadingButton from './LoadingButton';
import { AuthData } from './AuthForm';
import PasswordField from './PasswordField';
import { passwordConformsToPolicy } from '../utils/utils';


interface Props {
  readonly prompt?: string;
  readonly variant?: 'full' | 'user-only';
  readonly allowedAccountTypes?: AccountType[];
  readonly subscription?: {priceId: string, couponId: string};
  readonly offerId?: string;
  readonly invitationId?: string;
  readonly teamInvitationId?: string;
  readonly onSuccess?: (data: AuthData) => Promise<void>;
  readonly onError?: (error: any) => void;
  readonly onLoginClicked?: () => void;
}

export default function RegistrationFormV2(props: Props): JSX.Element {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [accountType, setAccountType] = useState<'sponsor' | 'broker' | 'lender' | 'investor' | undefined>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [tos, setTOS] = useState(false);

  const validAccountType = useMemo(() => {
    switch (props.variant) {
      case undefined:
      case 'full':
        return (props.allowedAccountTypes ?? ['sponsor', 'broker']).includes(accountType as any);
      default:
        return true;
    }
  }, [accountType, props.variant, props.allowedAccountTypes]);

  const signupClicked = useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!validAccountType) {
      return;
    }

    if (!passwordConformsToPolicy(password)) {
      console.error('Password does not conform to policy');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const sanitizedEmail = email.trim().toLowerCase();

      const metadata: Record<string, string> = {
        firstName: firstName,
        lastName: lastName,
        email: sanitizedEmail,
        phoneNumber: phoneNumber,
      };

      switch (props.variant) {
        case undefined:
        case 'full':
          if (accountType == null) {
            return;
          }
          metadata.companyName = companyName;
          metadata.accountType = accountType;
          metadata.isGuest = 'true';
          break;
        default:
          break;
      }

      if (props.subscription != null) {
        metadata.subscriptionPriceId = props.subscription.priceId;
        metadata.subscriptionCouponId = props.subscription.couponId;
      } else if (props.offerId != null) {
        metadata.subscriptionOfferId = props.offerId;
      }

      if (props.invitationId != null) {
        metadata.invitationId = props.invitationId;
      }

      if (props.teamInvitationId != null) {
        metadata.teamInvitationId = props.teamInvitationId;
      }

      await Auth.signUp({
        username: sanitizedEmail,
        password: password,
        clientMetadata: metadata,
      });

      if (props.onSuccess != null) {
        await props.onSuccess({
          email: sanitizedEmail,
          password
        });
      }
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.message);
      setLoading(false);

      if (props.onError != null) {
        props.onError(error);
      }
    }
  }, [validAccountType, companyName, firstName, lastName, email, phoneNumber, password, accountType, setLoading, props]);

  const contactCREEDClicked = useCallback(async (event: React.SyntheticEvent) => {
    window.open('mailto:sales@creed.tech?subject=Signup%20request');
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '40px',
      paddingTop: '48px',
    }}>
      <Typography
        sx={{
          paddingBottom: '32px',
        }}
        variant='h3'>
        {'Let\'s create your account'}
      </Typography>

      {props.prompt != null &&
        <Typography
          sx={{
            width: '300px',
            paddingBottom: '32px',
            color: '#333333BF',
            textAlign: 'center',
          }}>
          {props.prompt}
        </Typography>
      }

      <form
        onSubmit={validAccountType ? signupClicked : contactCREEDClicked}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
        }}>
        {props.variant !== 'user-only' &&
          <>
            <TextField
              sx={{width: 300}}
              required
              id='companyName'
              type='companyName'
              label='Company name'
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)} />
            <FormControl>
              <InputLabel id='accountTypeLabel'>{'Industry role'}</InputLabel>
              <Select
                id='accountType'
                labelId='accountTypeLabel'
                value={accountType}
                label='Industry role'
                required
                onChange={(e) => setAccountType(e.target.value as any)}>
                {props.allowedAccountTypes?.includes('sponsor') && <MenuItem value={'sponsor'}>{'Sponsor'}</MenuItem>}
                {props.allowedAccountTypes?.includes('broker') && <MenuItem value={'broker'}>{'Broker'}</MenuItem>}
                {props.allowedAccountTypes?.includes('lender') && <MenuItem value={'lender'}>{'Lender'}</MenuItem>}
                {props.allowedAccountTypes?.includes('investor') && <MenuItem value={'investor'}>{'LP/JV/Co-GP Investor'}</MenuItem>}
              </Select>
            </FormControl>
          </>
        }

        <TextField
          sx={{width: 300}}
          required
          id='firstName'
          type='firstName'
          label='First name'
          placeholder='Your first name'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value.trim())} />

        <TextField
          sx={{width: 300}}
          required
          id='lastName'
          type='lastName'
          label='Last name'
          placeholder='Your last name'
          value={lastName}
          onChange={(e) => setLastName(e.target.value.trim())} />

        <TextField
          sx={{width: 300}}
          required
          id='email'
          type='email'
          label='Email'
          value={email}
          onChange={e => setEmail(e.target.value.trim().toLowerCase())} />

        <MuiTelInput
          sx={{width: 300}}
          InputProps={{
            sx: {
              borderRadius: '8px',
            }
          }}
          defaultCountry={'US'}
          onlyCountries={['US']}
          disableDropdown
          value={phoneNumber}
          onChange={(val) => setPhoneNumber(val)} />

        <PasswordField
          sx={{width: 300}}
          required
          inputProps={{
            minLength: 8,
          }}
          id='password'
          label='Password'
          error={password.length > 0 && !passwordConformsToPolicy(password)}
          helperText={'Must be at least 8 characters'}
          value={password}
          onChange={(e) => setPassword(e.target.value)} />

        <FormGroup>
          <FormControlLabel
            label={(
              <Typography sx={{
                fontWeight: '400',
                fontSize: 12,
              }}>
                I agree to the <a href='/tos' target='_blank'>CREED Terms & Fees</a>
              </Typography>
            )}
            labelPlacement='end'
            control={(
              <Checkbox
                required
                checked={tos}
                color='primary'
                onChange={(e) => setTOS(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }} />
            )} />

        </FormGroup>

        {validAccountType || accountType == null ? (
          <LoadingButton
            variant='contained'
            type='submit'
            loading={loading}
            style={{height: '100%', width: 300, marginTop: '16px'}}>
            {'Sign up'}
          </LoadingButton>
        ) : (
          <Button
            variant='contained'
            type='submit'
            disabled={loading}
            style={{height: '100%', width: 300, marginTop: '16px'}}>
            {'Contact CREED sales'}
          </Button>
        )}

        {errorMessage &&
          <Box>
            <Typography variant='error'>
              {errorMessage}
            </Typography>
          </Box>
        }

        {props.onLoginClicked != null &&
          <Button
            variant='text'
            disabled={loading}
            onClick={props.onLoginClicked}
            style={{
              height: '100%',
              width: 300,
              marginTop: '12px',
            }}>
            {'Already have an account? Login'}
          </Button>
        }
      </form>
    </Box>
  );
}
