import { Dialog, DialogActions, DialogContent, DialogProps, Button, DialogContentText } from "@mui/material";
import { useCallback } from "react";
import { useAppDispatch } from "../../app/hooks";
import { useDeleteDocumentRequestSectionMutation } from "../../features/document-requests-api";
import { snacked } from "../../features/snackMessage-slice";
import { IDocumentRequestSection } from "../../schemas";
import CREEDDialogTitle from "../CREEDDialogTitle";
import LoadingButton from "../LoadingButton";
import * as Sentry from '@sentry/react';


export interface DeleteDocumentRequestSectionDialogProps extends DialogProps {
  section: IDocumentRequestSection;
}

export default function DeleteDocumentRequestSectionDialog({section, ...dialogProps}: DeleteDocumentRequestSectionDialogProps): JSX.Element {

  const dispatch = useAppDispatch();

  const [deleteSection, { isLoading: isDeleteSectionLoading }] = useDeleteDocumentRequestSectionMutation();

  const deleteClicked = useCallback(async () => {
    try {
      await deleteSection({
        dealId: section.dealId,
        sectionId: section._id,
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose({}, 'escapeKeyDown');
      }

      dispatch(snacked({
        message: 'Section deleted',
        severity: 'success',
      }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(snacked({
        message: 'Failed deleting section',
        severity: 'error',
      }));
    }
  }, [deleteSection, dialogProps, dispatch, section._id, section.dealId]);

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Delete Section'
        closeClicked={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null}/>
      <DialogContent>
        <DialogContentText>
          {'Are you sure you want to delete this section? All checklist items within it will also be deleted.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isDeleteSectionLoading}
          onClick={deleteClicked}>
          {'Delete Section'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
