import React, { useCallback } from 'react';
import { Box, Avatar, Typography, IconButton, Select, MenuItem, Tooltip } from '@mui/material';
import { IAccountTeam, IAccountTeamInvitation, IUser, TeamMemberAccessLevel } from '../../schemas';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useUpdateTeamMemberAccessMutation } from '../../features/accounts-api';
import { capitalize } from '../../utils/utils';


interface Props {
  readonly team?: IAccountTeam;
  readonly user?: IUser;
  readonly invitation?: IAccountTeamInvitation;
  readonly removeAccessClicked?: () => void;
  readonly copyLinkClicked?: () => void;
}

export default function TeamMemberRow({team, user, invitation, removeAccessClicked, copyLinkClicked}: Props): JSX.Element {

  const [updateTeamMemberAccess] = useUpdateTeamMemberAccessMutation();

  const accessLevelChanged = useCallback(async (accessLevel: TeamMemberAccessLevel) => {
    if (user == null) {
      return;
    }

    try {
      updateTeamMemberAccess({
        userId: user._id,
        accessLevel: accessLevel,
      })
    } catch (error) {
      console.error(error);
    }
  }, [user, updateTeamMemberAccess]);

  const accessLevelDisplay = useCallback((accessLevel: TeamMemberAccessLevel) => {
    switch (accessLevel) {
      case 'owner':
        return 'Owner';
      case 'member':
        return 'Member';
      case 'member-confidential':
        return 'Member w/ Confidential Access';
      default:
        return 'Unknown';
    }
  }, []);

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '24px',
        paddingBottom: '24px',
        alignItems: 'center',
      }}>
        <Box sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          gap: '12px',
        }}>
          <Avatar
            sx={{
              width: '40px',
              height: '40px',
              background: '#3333330A',
              color: '#3333334D',
            }} />

          {user != null ? (
            <Box sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
              }}>
                {`${user.firstName} ${user.lastName}`}
              </Typography>

              <Typography sx={{
                marginTop: '4px',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
                color: '#33333380',
              }}>
                {user.email}
              </Typography>
            </Box>
          ) : (
            <Box sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
                color: '#33333380',
              }}>
                {invitation?.email}
              </Typography>

              <Box sx={{
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '4px',
                paddingBottom: '4px',
                borderRadius: '30px',
                background: '#33333314',
              }}>
                <Typography sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '14.68px',
                }}>
                  {capitalize(invitation?.status ?? '')}
                </Typography>
              </Box>
            </Box>
          )}

        </Box>

        <Box sx={{
          flex: 1,
        }}>
          {(user == null || team?.userInfo[user._id]?.accessLevel === 'owner') ? (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '44px',
              alignSelf: 'flex-start',
              justifyContent: 'center',
              paddingLeft: '14px',
              paddingRight: '14px',
              width: '281px',
              borderRadius: '8px',
              background: '#3333330A',
            }}>
              <Typography>
                {user != null ? accessLevelDisplay(team?.userInfo[user._id].accessLevel ?? 'member') : accessLevelDisplay(invitation!.accessLevel)}
              </Typography>
            </Box>
          ) : (
            <Select
              sx={{
                height: '44px',
                width: '281px',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                borderRadius: '8px',
                background: '#3333330A',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
              }}
              disabled={user == null}
              value={user != null ? team?.userInfo[user._id].accessLevel ?? 'member' : invitation!.accessLevel}
              onChange={e => accessLevelChanged(e.target.value as TeamMemberAccessLevel)}>
              <MenuItem value='member'>{'Member'}</MenuItem>
              <MenuItem value='member-confidential'>{'Member w/ Confidential Access'}</MenuItem>
            </Select>
          )}
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 0.16,
          justifyContent: 'flex-end',
          gap: '12px',
        }}>
          {invitation != null && invitation.status === 'pending' &&
            <Tooltip
              title='Copy invitation link'
              arrow
              placement='top'
              enterDelay={500}>
              <IconButton
                sx={{color: '#33333380'}}
                onClick={copyLinkClicked}>
                <LinkRoundedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          }

          {(user == null || team?.userInfo[user._id].accessLevel !== 'owner') &&
            <Tooltip
              title='Remove from team'
              arrow
              placement='top'
              enterDelay={500}>
              <IconButton
                sx={{color: '#33333380'}}
                onClick={removeAccessClicked}>
                <DeleteOutlinedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          }
        </Box>
      </Box>
    </Box>
  );
}
