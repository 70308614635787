import { useCallback, useMemo, useContext } from 'react';
import { Avatar, Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IDocumentRequest } from '../../schemas';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Card from '../Card';
import { Params, useLoaderData, useNavigate } from 'react-router-dom';
import { useGetNotificationsQuery } from '../../features/notifications-api';
import dayjs from 'dayjs';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { DealActionContext } from '../Deal/DealDiligenceBase';
import { useGetDealDocumentRequestsQuery } from '../../features/document-requests-api';
import { useGetDealQuery } from '../../features/deals-api';
import DiligenceMilestonesCard from '../Deal/DealDiligenceDashboard/DiligenceMilestonesCard';
import DiligenceContactsCard from '../Deal/DealDiligenceDashboard/DiligenceContactsCard';
import DiligenceDealInfoCard from '../Deal/DealDiligenceDashboard/DiligenceDealInfoCard';


export default function DealDashboard(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const { dealId } = useLoaderData() as Params;

  const {
    submitDocumentRequestClicked,
    acceptDocumentRequestClicked,
    rejectDocumentRequestClicked,
  } = useContext(DealActionContext);

  const {data: account} = useGetCurrentAccountQuery();
  const {data: deal} = useGetDealQuery({dealId: dealId!}, { skip: dealId == null });
  const {
    data: documentRequests
  } = useGetDealDocumentRequestsQuery({ dealId: deal?._id ?? '' }, {
    skip: deal?._id == null,
    pollingInterval: 30_000,
  });

  const {data: notifications} = useGetNotificationsQuery();

  const priorities = useMemo(() => {
    if (documentRequests == null) {
      return [];
    }

    switch (account?.type) {
      case 'sponsor':
        return documentRequests.filter(dr => Boolean(dr.isSponsorPriority) && dr.status !== 'accepted' && dr.status !== 'submitted');
      case 'lender':
        return documentRequests.filter(dr => Boolean(dr.isLenderPriority) && dr.status === 'submitted');
      default:
        return [];
    }

  }, [documentRequests, account?.type]);

  const latestUpdates = useMemo(() => {
    return (notifications ?? [])
      .filter(n => n.dealId === deal?._id && (n.type === 'file_uploaded_deal' || documentRequests?.find(dr => dr._id === n.documentRequestId) != null))
      .slice(0, 5);
  }, [notifications, deal?._id, documentRequests]);

  const uploadFilesClicked = useCallback((documentRequest: IDocumentRequest) => {
    if (deal == null) {
      return;
    }

    navigate(`/deal/${deal._id}/documents/${documentRequest._id}`);
  }, [deal, navigate]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: 3,
      alignItems: 'flex-start'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        flex: 1,
      }}>
        <DiligenceContactsCard deal={deal} />
        <DiligenceDealInfoCard deal={deal} />
        <DiligenceMilestonesCard deal={deal} />
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        flex: 2.2,
      }}>
        <Card>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#333333',
            }}>
              {'Your priorities'}
            </Typography>

            <Typography sx={{
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '19.57px',
              color: '#333333BF',
            }}>
              {`${priorities.length} tasks`}
            </Typography>
          </Box>

          {priorities.map((docRequest, index) => (
            <Box key={docRequest._id}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '28px',
                paddingBottom: index < priorities.length - 1 ? '28px' : 0,
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  rowGap: '8px',
                  paddingRight: '12px',
                }}>
                  <Typography sx={{
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '19.57px',
                    color: '#000000',
                  }}>
                    {docRequest.name}
                  </Typography>

                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '17.12px',
                    color: '#333333BF',
                  }}>
                    {docRequest.description}
                  </Typography>
                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                }}>
                  {account?.type === 'sponsor' &&
                    <>
                      <Button
                        sx={{
                          height: '31px',
                          gap: '4px',
                          color: '#333333',
                        }}
                        variant={docRequest.files.length === 0 ? 'contained' : 'outlined'}
                        onClick={() => uploadFilesClicked(docRequest)}>
                        <FileUploadOutlinedIcon fontSize='small' />
                        {'Upload files'}
                      </Button>

                      {docRequest.files.length > 0 &&
                        <Button
                          sx={{
                            height: '31px',
                          }}
                          variant='contained'
                          disabled={!account?.currentSubscription?.isActive}
                          onClick={() => submitDocumentRequestClicked ? submitDocumentRequestClicked(docRequest) : null}>
                          {'Submit'}
                        </Button>
                      }
                    </>
                  }

                  {account?._id !== deal?.accountId && account?.type === 'lender' &&
                    <>
                      <Button
                        sx={{
                          height: '31px',
                        }}
                        variant='contained'
                        disabled={!account?.currentSubscription?.isActive}
                        onClick={() => acceptDocumentRequestClicked ? acceptDocumentRequestClicked(docRequest) : null}>
                        {'Approve'}
                      </Button>

                      <Button
                        sx={{
                          height: '31px',
                        }}
                        variant='outlined'
                        color='secondary'
                        disabled={!account?.currentSubscription?.isActive}
                        onClick={() => rejectDocumentRequestClicked ? rejectDocumentRequestClicked(docRequest) : null}>
                        {'Comment'}
                      </Button>
                    </>
                  }
                </Box>
              </Box>

              {index < priorities.length - 1 &&
                <Divider />
              }
            </Box>
          ))}
        </Card>

        <Card>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px',
            color: '#333333',
          }}>
            {'Latest updates'}
          </Typography>

          {latestUpdates.map((notification, index) => (
            <Box key={`${index}`}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '28px',
                paddingBottom: index < latestUpdates.length - 1 ? '28px' : 0,
              }}>
                <Avatar
                  sx={{
                    width: '32px',
                    height: '32px',
                  }}
                  alt={notification.title}
                  src={notification.senderAccount?.profileImageUrl} />

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '16px',
                }}>
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#000000',
                  }}>
                    {notification.title}
                  </Typography>

                  {notification.preview &&
                    <Typography sx={{
                      marginTop: '8px',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#333333BF',
                    }}>
                      {notification.preview}
                    </Typography>
                  }

                  <Typography sx={{
                    marginTop: '12px',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#33333380',
                  }}>
                    {`${dayjs(notification.createdDt).format('MMM D, YYYY [at] h:mm A')} • ${documentRequests?.find(dr => dr._id === notification.documentRequestId)?.name ?? 'General'}`}
                  </Typography>
                </Box>
              </Box>

              {index < latestUpdates.length - 1 &&
                <Divider />
              }
            </Box>
          ))}
        </Card>
      </Box>
    </Box>
  );
}
