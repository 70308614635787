import React, { useState, useCallback, forwardRef, useMemo, useContext, useEffect } from 'react';
import { Box, Typography, IconButton, Collapse, Tab, Tabs, Button, Divider, Menu, MenuItem, Avatar, TextField, Badge, Tooltip, CircularProgress } from '@mui/material';
import DocumentRequestStatusChip from './DocumentRequestStatusChip';
import DocumentRequestStatusIndicator from './DocumentRequestStatusIndicator';
import FileUploadBox from '../FileUploadBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NorthIcon from '@mui/icons-material/North';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DocumentRequestStatus, IFile, IDocumentRequest, IDeal, IAccount, IDocumentRequestEventParticipant } from '../../schemas';
import FileIcon from '../FileIcon';
import { humanFileSize, uploadFileToUrl } from '../../utils/utils';
import FileRow from '../FileRow';
import { useCreateDealFileUploadUrlMutation, useDeleteDealFileMutation, useLazyGetDealFileDownloadUrlQuery } from '../../features/deals-api';
import { useAppDispatch } from '../../app/hooks';
import axios from 'axios';
import { snacked } from '../../features/snackMessage-slice';
import fileDownload from 'js-file-download';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { useGetNotificationsQuery, useSetNotificationReadMutation } from '../../features/notifications-api';
import { DealActionContext } from './DealDiligenceBase';
import { useAddDocumentRequestEventMutation, useCreateDealDocumentRequestOptimisticMutation, useDeleteDocumentRequestFileMutation, useGetDealDocumentRequestAttachmentUploadUrlMutation, useGetDealDocumentRequestsQuery, useGetDealDocumentRequestUploadUrlMutation, useLazyGetDocumentRequestAttachmentUrlQuery, useLazyGetDocumentRequestFileUrlQuery, useUpdateDealDocumentRequestOptimisticMutation } from '../../features/document-requests-api';
import CommentRow from '../CommentRow';
import UnapproveDocumentRequestDialog from './UnapproveDocumentRequestDialog';
import { usePopoverContext } from '../../utils/hooks';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LockIcon from '@mui/icons-material/Lock';
import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';
import EditableTextField from '../EditableTextField';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import * as Sentry from '@sentry/react';
import { useDropzone } from 'react-dropzone';


export interface DocumentRequestSectionData {
  readonly key: string;
  readonly title: string;
  readonly description?: string;
  readonly status?: DocumentRequestStatus;
  readonly files: IFile[];
  readonly documentRequest?: IDocumentRequest;
}

interface Props {
  readonly editable?: boolean;
  readonly canDrag?: boolean;
  readonly draggingDisabled?: boolean;
  readonly dragHandleProps?: DraggableProvidedDragHandleProps | null;
  readonly open?: boolean;
  readonly closable?: boolean;
  readonly deal?: IDeal;
  readonly participants?: IAccount[];
  readonly documentRequest: IDocumentRequest;
}

const DocumentRequestRow = forwardRef<HTMLElement, Props>(({
  editable,
  canDrag,
  draggingDisabled,
  dragHandleProps,
  open: propsOpen,
  closable,
  deal,
  participants,
  documentRequest,
}, ref): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    makeDocumentRequestConfidential,
    manageDocumentRequestAccess,
    setDocumentRequestAsPriority,
    deleteDocumentRequest,
    previewFile,
    submitDocumentRequestClicked,
    unsubmitDocumentRequestClicked,
    acceptDocumentRequestClicked,
    rejectDocumentRequestClicked,
  } = useContext(DealActionContext);

  const {
    props: unapproveDocumentRequestDialogProps,
    showDialog: showUnapproveDocumentRequest,
  } = usePopoverContext();

  const [open, setOpen] = useState(propsOpen ?? false);
  const [name, setName] = useState(documentRequest.name);
  const [description, setDescription] = useState(documentRequest.description);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement | undefined>();
  const [fileMenuAnchorElem, setFileMenuAnchorElem] = useState<HTMLElement | undefined>();
  const [isAttachmentsUploading, setIsAttachmentsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IFile | undefined>();
  const [currentComment, setCurrentComment] = useState('');
  const [addingItemBelow, setAddingItemBelow] = useState(false);

  const {data: account} = useGetCurrentAccountQuery();
  const {refetch: refetchDocumentRequests} = useGetDealDocumentRequestsQuery({dealId: deal?._id!}, {skip: deal?._id == null});
  const {data: notifications} = useGetNotificationsQuery();
  const [getDocumentRequestAttachmentUrl] = useLazyGetDocumentRequestAttachmentUrlQuery();

  const [getDocumentRequestFileDownloadUrl] = useLazyGetDocumentRequestFileUrlQuery();
  const [getDealFileDownloadUrl] = useLazyGetDealFileDownloadUrlQuery();
  const [getDocumentRequestUploadUrl] = useGetDealDocumentRequestUploadUrlMutation();
  const [createDealFileUploadUrl] = useCreateDealFileUploadUrlMutation();
  const [deleteDocumentRequestFile] = useDeleteDocumentRequestFileMutation();
  const [deleteFile] = useDeleteDealFileMutation();
  const [addDocumentRequestEvent] = useAddDocumentRequestEventMutation();
  const [setNotificationRead] = useSetNotificationReadMutation();
  const [updateDocumentRequestOptimistic] = useUpdateDealDocumentRequestOptimisticMutation();
  const [createDocumentRequestOptimistic, { isLoading: isCreateDocumentRequestOptimisticLoading }] = useCreateDealDocumentRequestOptimisticMutation();
  const [getAttachmentUploadUrl] = useGetDealDocumentRequestAttachmentUploadUrlMutation();

  const canUploadFiles = account?.currentSubscription?.isActive
    && (documentRequest == null || documentRequest.status === 'pending' || documentRequest.status === 'rejected')
    && (account?.type === 'sponsor' || account?.type === 'broker' || documentRequest == null);

  const messageEvents = useMemo(() => {
    if (documentRequest == null) {
      return [];
    }

    const events = documentRequest.events?.filter(e => e.message != null);
    return events ?? [];
  }, [documentRequest]);

  const participantsMap = useMemo(() => {
    let map = participants?.reduce((obj, account) => {
      return {
        ...obj,
        [account._id]: {
          accountId: account._id,
          accountName: account.name,
          accountType: account.type,
          accountProfileImageUrl: account.profileImageUrl,
        },
      };
    }, {} as Record<string, IDocumentRequestEventParticipant>);

    if (documentRequest != null) {
      const participantCacheMap = documentRequest.eventParticipants?.reduce((obj, participant) => {
        return {
          ...obj,
          [participant.accountId]: participant,
        };
      }, {} as Record<string, IDocumentRequestEventParticipant>);

      map = {
        ...participantCacheMap,
        ...map ?? {},
      };
    }

    return map ?? {};
  }, [participants, documentRequest]);

  const unreadCommentNotifications = useMemo(() => {
    if (documentRequest == null) {
      return undefined;
    }

    return notifications?.filter(n => !n.isRead && n.type === 'document_request_commented' && n.documentRequestId === documentRequest?._id);
  }, [notifications, documentRequest]);

  const nameFinishedEditing = useCallback(() => {
    if (documentRequest._id == null) {
      return;
    }

    if (documentRequest.name !== name) {
      updateDocumentRequestOptimistic({
        dealId: documentRequest.dealId,
        documentRequestId: documentRequest._id,
        changes: {
          name,
        }
      });
    }
  }, [documentRequest._id, documentRequest.dealId, documentRequest.name, name, updateDocumentRequestOptimistic]);

  const descriptionFinishedEditing = useCallback(() => {
    if (documentRequest._id == null) {
      return;
    }

    if (documentRequest.description !== description) {
      updateDocumentRequestOptimistic({
        dealId: documentRequest.dealId,
        documentRequestId: documentRequest._id,
        changes: {
          description,
        }
      });
    }
  }, [description, documentRequest._id, documentRequest.dealId, documentRequest.description, updateDocumentRequestOptimistic]);

  const submitClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (documentRequest == null || submitDocumentRequestClicked == null) {
      return;
    }

    submitDocumentRequestClicked(documentRequest);
  }, [documentRequest, submitDocumentRequestClicked]);

  const unsubmitClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (documentRequest == null || unsubmitDocumentRequestClicked == null) {
      return;
    }

    unsubmitDocumentRequestClicked(documentRequest);
  }, [documentRequest, unsubmitDocumentRequestClicked]);

  const approveClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (documentRequest == null || acceptDocumentRequestClicked == null) {
      return;
    }

    acceptDocumentRequestClicked(documentRequest);
  }, [documentRequest, acceptDocumentRequestClicked]);

  const commentClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (documentRequest == null || rejectDocumentRequestClicked == null) {
      return;
    }

    rejectDocumentRequestClicked(documentRequest);
  }, [documentRequest, rejectDocumentRequestClicked]);

  const moreClicked = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorElem(event.currentTarget);
  }, [setMenuAnchorElem]);

  const addChecklistItemMouseEnter = useCallback(() => {
    setAddingItemBelow(true);
  }, []);

  const addChecklistItemMouseLeave = useCallback(() => {
    setAddingItemBelow(false);
  }, []);

  const addChecklistItemClicked = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (deal?._id == null || documentRequest._id == null) {
      return;
    }

    createDocumentRequestOptimistic({
      dealId: deal._id,
      sectionId: documentRequest.sectionId,
      preceedingDocumentRequestId: documentRequest._id,
    });
  }, [createDocumentRequestOptimistic, deal?._id, documentRequest._id, documentRequest.sectionId]);

  const unapproveClicked = useCallback(() => {
    showUnapproveDocumentRequest();
    setMenuAnchorElem(undefined);
  }, [showUnapproveDocumentRequest]);

  const copyLinkClicked = useCallback(async () => {
    if (deal == null) {
      return;
    }

    const docRequestId = documentRequest != null ? documentRequest._id : 'general';
    const url = `${window.location.protocol}//${window.location.host}/deal/${deal._id}/documents/${docRequestId}`;
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy', true, url);
    }

    setMenuAnchorElem(undefined);

    dispatch(snacked({
      message: 'Link copied to clipboard',
      severity: 'info',
    }));
  }, [dispatch, deal, documentRequest]);

  const makeConfidentialClicked = useCallback(() => {
    if (documentRequest == null) {
      return;
    }

    if (makeDocumentRequestConfidential != null) {
      makeDocumentRequestConfidential(documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [documentRequest, makeDocumentRequestConfidential, setMenuAnchorElem]);

  const setAsPriorityClicked = useCallback(() => {
    if (documentRequest == null) {
      return;
    }

    if (setDocumentRequestAsPriority != null) {
      setDocumentRequestAsPriority(documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [documentRequest, setDocumentRequestAsPriority, setMenuAnchorElem]);

  const manageAccessClicked = useCallback(() => {
    if (documentRequest == null) {
      return;
    }

    if (manageDocumentRequestAccess != null) {
      manageDocumentRequestAccess(documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [documentRequest, manageDocumentRequestAccess, setMenuAnchorElem]);

  const deleteClicked = useCallback(() => {
    if (documentRequest == null) {
      return;
    }

    if (deleteDocumentRequest != null) {
      deleteDocumentRequest(documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [setMenuAnchorElem, deleteDocumentRequest, documentRequest]);

  const onAttachmentFilesDropped = useCallback(async (acceptedFiles: File[]) => {
    if (documentRequest._id == null) {
      return;
    }

    try {
      setIsAttachmentsUploading(true);

      const fileUploadUrlResponses = await Promise.all(acceptedFiles.map(f => getAttachmentUploadUrl({
        dealId: documentRequest.dealId,
        documentRequestId: documentRequest._id!,
        contentType: f.type,
        fileName: f.name,
      }).unwrap()));

      await Promise.all(fileUploadUrlResponses.map((response, index) => {
        if (!response.url) {
          return null;
        }

        const file = acceptedFiles[index];
        return axios.put(response.url, file, {
          headers: {
            'Content-Type': file.type
          },
        });
      }));

      await refetchDocumentRequests();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed uploading attachments',
        severity: 'error',
      }));
    } finally {
      setIsAttachmentsUploading(false);
    }
  }, [dispatch, documentRequest._id, documentRequest.dealId, getAttachmentUploadUrl, refetchDocumentRequests]);

  const onDocumentRequestFilesDropped = useCallback(async (acceptedFiles: File[]) => {
    if (deal?._id == null || documentRequest?._id == null) {
      return;
    }

    if (documentRequest != null && documentRequest._id != null) {
      // Upload files to the document request
      setLoading(true);

      try {
        const uploadUrlResponses = await Promise.all(acceptedFiles.map(f => getDocumentRequestUploadUrl({
          dealId: deal?._id!,
          documentRequestId: documentRequest._id!,
          fileName: f.name,
          contentType: f.type,
        }).unwrap()));

        await Promise.all(uploadUrlResponses.map((response, index) => {
          if (!response.url) {
            return null;
          }

          const file = acceptedFiles[index];
          return axios.put(response.url, file, {
            headers: {
              'Content-Type': file.type,
            }
          });
        }));

        refetchDocumentRequests();
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        dispatch(snacked({
          message: 'Failed uploading files',
          severity: 'error',
        }));
      } finally {
        setLoading(false);
      }
    } else {
      // Upload files directly to deal (General)
      setLoading(true);

      try {
        const uploadUrlResponses = await Promise.all(acceptedFiles.map(file => createDealFileUploadUrl({
          dealId: deal?._id!,
          fileType: 'file',
          fileName: file.name,
          contentType: file.type,
        }).unwrap()));

        await Promise.all(
          uploadUrlResponses.map((response, index) => uploadFileToUrl(response.url, acceptedFiles[index]))
        );
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        dispatch(snacked({
          message: 'Failed uploading files',
          severity: 'error',
        }));
      } finally {
        setLoading(false);
      }
    }
  }, [deal?._id, documentRequest, getDocumentRequestUploadUrl, dispatch, createDealFileUploadUrl, refetchDocumentRequests]);

  const previewFileClicked = useCallback(async (file: IFile) => {
    if (deal?._id == null || documentRequest._id == null) {
      return;
    }

    let url: string | undefined = undefined;
    if (documentRequest != null) {
      const result = await getDocumentRequestFileDownloadUrl({
        dealId: deal._id,
        documentRequestId: documentRequest._id,
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    } else {
      const result = await getDealFileDownloadUrl({
        dealId: deal._id,
        fileType: 'file',
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    }

    if (previewFile != null && url != null) {
      previewFile(file.name, url);
    }
  }, [deal?._id, documentRequest, previewFile, getDocumentRequestFileDownloadUrl, getDealFileDownloadUrl]);

  const downloadFileClicked = useCallback(async (file: IFile) => {
    if (deal?._id == null || documentRequest._id == null) {
      return;
    }

    let url: string | undefined = undefined;
    if (documentRequest != null) {
      const result = await getDocumentRequestFileDownloadUrl({
        dealId: deal._id,
        documentRequestId: documentRequest._id,
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    } else {
      const result = await getDealFileDownloadUrl({
        dealId: deal._id,
        fileType: 'file',
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    }

    if (url) {
      const signedUrlResponse = await axios.get(url, {
        responseType: 'blob'
      });

      const { data: blob } = signedUrlResponse;
      fileDownload(blob, file.name ?? file.key);
    }
  }, [deal?._id, documentRequest, getDocumentRequestFileDownloadUrl, getDealFileDownloadUrl]);

  const fileMenuClicked = useCallback((event: React.MouseEvent<HTMLElement>, file: IFile) => {
    setSelectedFile(file);
    setFileMenuAnchorElem(event.currentTarget);
  }, [setSelectedFile, setFileMenuAnchorElem]);

  const copyFileLinkClicked = useCallback(async () => {
    if (deal == null || selectedFile == null) {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
      return;
    }

    const docReqId = documentRequest == null ? 'general' : documentRequest._id;

    const url = `${window.location.protocol}//${window.location.host}/deal/${deal._id}/documents/${docReqId}?file=${encodeURIComponent(selectedFile.key)}`;
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy', true, url);
    }

    setFileMenuAnchorElem(undefined);
    setSelectedFile(undefined);

    dispatch(snacked({
      message: 'Link copied to clipboard',
      severity: 'info',
    }));
  }, [deal, selectedFile, documentRequest, setFileMenuAnchorElem, setSelectedFile, dispatch]);

  const deleteFileClicked = useCallback(async () => {
    if (deal?._id == null || selectedFile == null || documentRequest._id == null) {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
      return;
    }

    try {
      if (documentRequest != null) {
        deleteDocumentRequestFile({
          dealId: deal._id,
          documentRequestId: documentRequest._id,
          fileKey: selectedFile.key,
        });
      } else {
        deleteFile({
          id: deal._id,
          fileKey: selectedFile.key,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    } finally {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
    }
  }, [deal?._id, selectedFile, documentRequest, deleteDocumentRequestFile, deleteFile]);

  const documentRequestAttachmentClicked = useCallback(async (file: IFile) => {
    if (deal?._id == null || documentRequest == null || documentRequest._id == null) {
      return;
    }

    const {url} = await getDocumentRequestAttachmentUrl({
      dealId: deal._id,
      documentRequestId: documentRequest._id,
      fileKey: file.key,
    }).unwrap();

    if (url) {
      const signedUrlResponse = await axios.get(url, {
        responseType: 'blob'
      });

      const { data: blob } = signedUrlResponse;
      fileDownload(blob, file.name ?? file.key);
    }
  }, [deal, getDocumentRequestAttachmentUrl, documentRequest]);

  const addCommentClicked = useCallback(() => {
    if (deal?._id == null || documentRequest == null || account == null || !currentComment || documentRequest._id == null) {
      return;
    }

    addDocumentRequestEvent({
      dealId: deal._id,
      documentRequestId: documentRequest._id,
      message: currentComment,
      senderAccount: account,
    });

    setCurrentComment('');
  }, [currentComment, setCurrentComment, account, addDocumentRequestEvent, deal?._id, documentRequest]);

  const {
    getRootProps: getAttachmentUploadRootProps,
    getInputProps: getAttachmentUploadInputProps,
  } = useDropzone({
    onDrop: onAttachmentFilesDropped,
    noDrag: true,
  });

  useEffect(() => {
    if (currentTab === 1 && unreadCommentNotifications != null) {
      for (let notification of unreadCommentNotifications) {
        setNotificationRead({
          notificationId: notification._id as string,
          isRead: true
        });
      }
    }
  }, [currentTab, setNotificationRead, unreadCommentNotifications]);

  return (
    <Box
      ref={ref}
      sx={{
        background: 'white',
      }}>
      <Box sx={{
        cursor: 'pointer',
      }}>
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 4,
            paddingRight: 2,
            ':hover:not(:active)': {
              background: '#33333308',
            },
          }}
          onClick={() => documentRequest._id != null && closable !== false ? setOpen(!open) : null}>
          <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            gap: '14px',
          }}>
            {documentRequest._id == null ? (
              <CircularProgress
                sx={{
                  color: '#33333330',
                }}
                size='18px' />
            ) : editable && canDrag ? (
              <Box
                sx={{
                  marginTop: '6px',
                }}
                {...dragHandleProps}>
                <DragIndicatorIcon
                  sx={{
                    fontSize: '18px',
                    color: draggingDisabled ? '#33333330' : '#33333380',
                  }} />
              </Box>
            ) : (<></>)}

            {closable !== false &&
              <IconButton
                size='small'
                onClick={() => setOpen(!open)}>
                {open ? (
                  <ExpandMoreIcon
                    sx={{
                      color: '#333333',
                    }}
                    fontSize='small' />
                ) : (
                  <ChevronRightIcon
                    sx={{
                      color: '#333333',
                    }}
                    fontSize='small' />
                )}
              </IconButton>
            }

            <DocumentRequestStatusIndicator status={documentRequest.status ?? 'pending'} />

            <EditableTextField
              editable={editable && documentRequest._id != null}
              typographyProps={{
                sx: {
                  fontWeight: '400',
                  fontSize: '13px',
                  lineHeight: '15.9px',
                },
              }}
              textFieldProps={{
                size: 'small',
                InputProps: {
                  sx: {
                    fontWeight: '400',
                    fontSize: '13px',
                    lineHeight: '15.9px',
                  }
                },
              }}
              value={name}
              onChange={v => setName(v ?? '')}
              onStartEditing={() => null}
              onFinishEditing={nameFinishedEditing} />

            {documentRequest?.securityLevel === 'confidential' &&
              <Tooltip
                title={'This document request is confidential. It\'s only visible to you, the sponsor/lender and the stakeholders invited to it.'}
                placement='top'
                enterDelay={500}
                arrow>
                <LockIcon sx={{
                  fontSize: '14px',
                  color: '#33333380',
                }} />
              </Tooltip>
            }

            {documentRequest?.status != null &&
              <DocumentRequestStatusChip
                status={documentRequest.status}
                accountType={account?.type} />
            }

            {((account?.type === 'lender' && documentRequest?.isLenderPriority) || (['sponsor', 'broker'].includes(account?.type ?? '') && documentRequest?.isSponsorPriority)) &&
              <Box sx={{
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
                borderRadius: '30px',
                background: '#F8333C1A',
              }}>
                <Typography sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '14.68px',
                  color: '#B1060F',
                }}>
                  {`${account?.type === 'lender' ? 'Lender' : 'Borrower'} priority item`}
                </Typography>
              </Box>
            }

            {((account?.type === 'lender' && documentRequest?.isSponsorPriority) || (['sponsor', 'broker'].includes(account?.type ?? '') && documentRequest?.isLenderPriority)) &&
              <Box sx={{
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
                borderRadius: '30px',
                background: '#33333314',
              }}>
                <Typography sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '14.68px',
                  color: '#333333',
                }}>
                  {`${account?.type === 'lender' ? 'Borrower' : 'Lender'} priority item`}
                </Typography>
              </Box>
            }
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            {account?.currentSubscription?.isActive
              && documentRequest != null
              && (documentRequest.files ?? []).length > 0
              && (documentRequest.status === 'pending' || documentRequest.status === 'rejected')
              && (account?.type === 'sponsor' || account.type === 'broker') &&
              <Button
                sx={{
                  height: '31px',
                  marginRight: '20px',
                }}
                variant='contained'
                onClick={submitClicked}>
                {'Submit'}
              </Button>
            }

            {account?.currentSubscription?.isActive
              && documentRequest != null
              && documentRequest.status === 'submitted'
              && (account?.type === 'sponsor' || account.type === 'broker') &&
              <Button
                sx={{
                  height: '31px',
                  marginRight: '20px',
                }}
                variant='contained'
                onClick={unsubmitClicked}>
                {'Un-submit'}
              </Button>
            }

            {account?.currentSubscription?.isActive
              && documentRequest != null
              && account?._id !== deal?.accountId
              && account.type === 'lender' &&
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
                paddingRight: '20px',
              }}>
                {documentRequest.status === 'submitted' &&
                  <>
                    <Button
                      sx={{
                        height: '31px',
                      }}
                      variant='contained'
                      onClick={approveClicked}>
                      {'Approve'}
                    </Button>

                    <Button
                      sx={{
                        height: '31px',
                        color: '#ffffff',
                        background: 'linear-gradient(90.87deg, #333333 47.63%, #DDCFB2 152.29%)',
                      }}
                      variant='contained'
                      onClick={commentClicked}>
                      {'Comment'}
                    </Button>
                  </>
                }
              </Box>
            }

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
            }}>
              <IconButton
                size='small'
                color='info'
                disabled={documentRequest._id == null}
                onClick={moreClicked}>
                <MoreHorizIcon sx={{
                  fontSize: '18px',
                }} />
              </IconButton>

              {editable && closable &&
                <Tooltip
                  title='Add new checklist item below'
                  arrow
                  placement='right'
                  enterDelay={1000}>
                  <span>
                    <IconButton
                      size='small'
                      disabled={isCreateDocumentRequestOptimisticLoading || documentRequest._id == null}
                      onMouseEnter={addChecklistItemMouseEnter}
                      onMouseLeave={addChecklistItemMouseLeave}
                      onClick={addChecklistItemClicked}>
                      <AddRoundedIcon sx={{
                        fontSize: '18px',
                      }} />
                    </IconButton>
                  </span>
                </Tooltip>
              }
            </Box>
          </Box>
        </Box>

        <Collapse
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
          }}
          in={open}>
          <Box sx={{
            paddingBottom: 3,
          }}>
            {documentRequest != null &&
              <Tabs
                indicatorColor='primary'
                value={currentTab}
                onChange={(e, value) => setCurrentTab(value)}>
                <Tab label='Documents' />
                <Tab
                  label='Comments'
                  icon={
                    Boolean(unreadCommentNotifications) ?
                      <Badge
                        sx={{
                          paddingLeft: Boolean(unreadCommentNotifications) ? '8px' : 0,
                          fontWeight: '500',
                          fontSize: '13px',
                        }}
                        invisible={!Boolean(unreadCommentNotifications)}
                        badgeContent={unreadCommentNotifications?.length}
                        color='primary' />
                    : undefined
                  }
                  iconPosition='end' />
              </Tabs>
            }

            <div
              role="tabpanel"
              hidden={documentRequest != null && currentTab !== 0}
              id={`tabpanel-dashboard`}
              aria-labelledby={`tab-dashboard`}>
              {currentTab === 0 && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '24px',
                  rowGap: '24px',
                }}>
                  <EditableTextField
                    editable={editable && documentRequest._id != null}
                    typographyProps={{
                      sx: {
                        whiteSpace: 'pre-wrap',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '17.12px',
                        color: '#333333BF',
                      },
                    }}
                    textFieldProps={{
                      size: 'small',
                      InputProps: {
                        sx: {
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '17.12px',
                          color: '#333333BF',
                        },
                        multiline: true,
                        minRows: 6,
                      },
                    }}
                    value={description}
                    onChange={v => setDescription(v ?? '')}
                    onStartEditing={() => null}
                    onFinishEditing={descriptionFinishedEditing} />

                  {(documentRequest?.attachments || account?.type === 'lender') &&
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1,
                      }}>
                        <Typography sx={{
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '14.68px',
                          color: '#33333380',
                        }}>
                          {'ATTACHMENTS'}
                        </Typography>

                        {account?.type === 'lender' &&
                          <Box component='div' {...getAttachmentUploadRootProps()}>
                            {isAttachmentsUploading ? (
                              <CircularProgress
                                sx={{
                                  color: '#333333AA'
                                }}
                                size='12px' />
                            ) : (
                              <>
                                <Tooltip
                                  title='Upload attachments'
                                  arrow
                                  enterDelay={750}
                                  placement='right'>
                                  <IconButton
                                    size='small'>
                                    <AddRoundedIcon sx={{
                                      fontSize: '14px',
                                    }} />
                                  </IconButton>
                                </Tooltip>
                                <Box component='input' {...getAttachmentUploadInputProps()} />
                              </>
                            )}
                          </Box>
                        }
                      </Box>

                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: '8px',
                        paddingTop: '8px',
                      }}>
                        {documentRequest.attachments?.map(attachment => (
                          <Box
                            key={attachment.key}
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              height: '60px',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: 1,
                              padding: '12px',
                              borderRadius: '8px',
                              border: '1px solid rgba(51, 51, 51, 0.08)',
                            }}>
                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                              <FileIcon
                                contentType={attachment.contentType}
                                color={'#33333380'} />

                              <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingLeft: '16px',
                                rowGap: '4px',
                              }}>
                                <Typography sx={{
                                  // flex: 1,
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  color: '#333333',
                                }}>
                                  {attachment.name ?? attachment.key}
                                </Typography>

                                {attachment.size != null &&
                                  <Typography sx={{
                                    fontWeight: '400',
                                    fontSize: '13px',
                                    lineHeight: '16px',
                                    color: '#33333380',
                                  }}>
                                    {humanFileSize(attachment.size, true)}
                                  </Typography>
                                }
                              </Box>
                            </Box>

                            <IconButton onClick={() => documentRequestAttachmentClicked(attachment)}>
                              <FileDownloadOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>

                      {(documentRequest.files.length > 0 || canUploadFiles) &&
                        <Divider  sx={{
                          marginTop: '16px',
                          bgcolor: '#33333314',
                        }} />
                      }
                    </Box>
                  }

                  {documentRequest.files.map((file, index) => (
                    <Box
                      key={file.key}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <FileRow
                        file={file}
                        canDelete={true}
                        previewClicked={previewFile != null ? () => previewFileClicked(file) : undefined}
                        downloadClicked={() => downloadFileClicked(file)}
                        moreClicked={(e) => fileMenuClicked(e, file)} />

                      {index < documentRequest.files.length - 1 &&
                        <Divider sx={{
                          marginTop: '20px',
                          bgcolor: '#33333314'
                        }} />
                      }
                    </Box>
                  ))}

                  {canUploadFiles &&
                    <FileUploadBox
                      loading={loading}
                      onFilesDropped={onDocumentRequestFilesDropped} />
                  }
                </Box>
              )}
            </div>

            <div
              role="tabpanel"
              hidden={documentRequest == null || currentTab !== 1}
              id={`tabpanel-documents`}
              aria-labelledby={`tab-documents`}>
              {currentTab === 1 && (
                <Box sx={{
                  paddingTop: '24px',
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    padding: '8px 16px',
                    alignItems: 'center',
                    border: '1px solid rgba(51, 51, 51, 0.2)',
                    borderRadius: '8px',
                  }}>
                    <Avatar
                      sx={{
                        width: '32px',
                        height: '32px',
                      }}
                      alt={'your account'}
                      src={account?.profileImageUrl} />

                    <TextField
                      sx={{
                        display: 'flex',
                        flex: 1,
                        paddingLeft: '12px',
                        paddingRight: '12px',
                      }}
                      variant='standard'
                      placeholder={'Write a message...'}
                      disabled={!account?.currentSubscription?.isActive}
                      value={currentComment}
                      onChange={(e) => setCurrentComment(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                      }} />

                    <IconButton
                      sx={{
                        width: '36px',
                        height: '36px',
                      }}
                      color='secondary'
                      disabled={currentComment.length === 0 || !account?.currentSubscription?.isActive}
                      onClick={addCommentClicked}>
                      <NorthIcon sx={{fontSize: '14px'}} />
                    </IconButton>
                  </Box>

                  {messageEvents.length > 0 &&
                    <Divider sx={{
                      marginTop: '24px',
                    }} />
                  }

                  {messageEvents.map((event, index) => (
                    <CommentRow
                      key={event._id}
                      avatarSize='small'
                      avatarImageUrl={participantsMap[event.accountId]?.accountProfileImageUrl}
                      name={`${participantsMap[event.accountId]?.accountName ?? 'Unknown'}${event.accountId === account?._id ? ' (you)' : ''}`}
                      comment={event.message ?? ''}
                      date={event.createdDt} />
                  ))}
                </Box>
              )}
            </div>
          </Box>

          <Menu
            open={menuAnchorElem != null}
            anchorEl={menuAnchorElem}
            onClose={() => setMenuAnchorElem(undefined)}>
            {account?.currentSubscription?.isActive
            && account?.type === 'lender'
            && documentRequest?.status === 'accepted' &&
              <Box>
                <MenuItem onClick={unapproveClicked}>
                  <KeyboardReturnOutlinedIcon
                    sx={{color: '#33333380'}}
                    fontSize='small'/>
                  <Typography variant='menuItem'>
                    {'Un-approve'}
                  </Typography>
                </MenuItem>

                <Divider />
              </Box>
            }
            <MenuItem onClick={copyLinkClicked}>
              <LinkRoundedIcon
                sx={{color: '#33333380'}}
                fontSize='small' />
              <Typography variant='menuItem'>
                {'Copy link'}
              </Typography>
            </MenuItem>
            {account?.currentSubscription?.isActive
            && (account.type === 'sponsor' || account.type === 'broker' || account.type === 'lender')
            && documentRequest != null
            && documentRequest.securityLevel !== 'confidential' &&
              <MenuItem onClick={makeConfidentialClicked}>
                <LockOutlinedIcon
                  sx={{color: '#33333380'}}
                  fontSize='small' />
                <Typography variant='menuItem'>
                  {'Make confidential'}
                </Typography>
              </MenuItem>
            }
            {account?.currentSubscription?.isActive
            && (account.type === 'sponsor' || account.type === 'broker' || account.type === 'lender')
            && documentRequest != null &&
              <MenuItem
                divider={editable && documentRequest.securityLevel !== 'confidential'}
                onClick={setAsPriorityClicked}>
                <PriorityHighIcon
                  sx={{color: '#33333380'}}
                  fontSize='small' />
                <Typography variant='menuItem'>
                  {((account.type === 'sponsor' || account.type === 'broker') && documentRequest.isLenderPriority) || (account.type === 'lender' && documentRequest.isSponsorPriority)
                    ? 'Remove as priority'
                    : 'Set as priority'
                  }
                </Typography>
              </MenuItem>
            }
            {account?.currentSubscription?.isActive
            && (account.type === 'sponsor' || account.type === 'broker' || account.type === 'lender')
            && documentRequest != null
            && documentRequest.securityLevel === 'confidential' &&
              <MenuItem
                divider={editable}
                onClick={manageAccessClicked}>
                <PersonAddOutlinedIcon
                  sx={{color: '#33333380'}}
                  fontSize='small' />
                <Typography variant='menuItem'>
                  {'Manage access'}
                </Typography>
              </MenuItem>
            }
            {account?.currentSubscription?.isActive
            && account?.type === 'lender'
            && documentRequest != null
            && documentRequest.status !== 'accepted' &&
              <MenuItem onClick={deleteClicked}>
                <DeleteOutlinedIcon
                  sx={{color: '#33333380'}}
                  fontSize='small' />
                <Typography variant='menuItem'>
                  {'Delete'}
                </Typography>
              </MenuItem>
            }
          </Menu>

          <Menu
            open={fileMenuAnchorElem != null}
            anchorEl={fileMenuAnchorElem}
            onClose={() => setFileMenuAnchorElem(undefined)}>
            <MenuItem onClick={copyFileLinkClicked}>
              <LinkRoundedIcon
                sx={{color: '#33333380'}}
                fontSize='small' />
              <Typography variant='menuItem'>
                {'Copy link'}
              </Typography>
            </MenuItem>
            {account?.currentSubscription?.isActive
            && (documentRequest == null
              || ((documentRequest.status === 'pending' || documentRequest.status === 'rejected') && (account?.type === 'sponsor' || account?.type === 'broker') && account?._id === deal?.accountId)) &&
              <MenuItem onClick={deleteFileClicked}>
                <DeleteOutlinedIcon
                  sx={{color: '#33333380'}}
                  fontSize='small' />
                <Typography variant='menuItem'>
                  {'Delete file'}
                </Typography>
              </MenuItem>
            }
          </Menu>
        </Collapse>
      </Box>

      <Divider sx={addingItemBelow
        ? {
          borderBottomWidth: '2px',
          background: '#D9B361',
        }
        : {
          borderBottomWidth: '1px',
          background: '#33333301'
        }} />

      {documentRequest != null &&
        <UnapproveDocumentRequestDialog
          {...unapproveDocumentRequestDialogProps}
          documentRequest={documentRequest} />
      }
    </Box>
  );
});

export default DocumentRequestRow;
