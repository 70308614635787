import { Box, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import PageContainer from "../PageContainer";
import { useGetInvestorAccountDirectoryQuery } from "../../features/utils-api";
import { useMemo } from "react";
import { IAccount } from "../../schemas";
import { wrapColumnsInTooltips } from "../../utils/componentUtils";
import { abbreviatedNumber } from "../../utils/utils";


export default function InvestorDirectory(): JSX.Element {

  const {data: investors, isLoading: isInvestorDirectoryLoading} = useGetInvestorAccountDirectoryQuery();

  const indexMap: Record<string, number> = useMemo(() => {
    return investors?.reduce((obj, investor, index) => {
      return {
        ...obj,
        [investor._id]: index,
      };
    }, {} as any);
  }, [investors]);

  const columns: GridColDef<Pick<IAccount, '_id' | 'profile'>>[] = useMemo(() => {
    return wrapColumnsInTooltips([
      {
        field: 'number',
        headerName: 'CREED Investor',
        width: 60,
        valueGetter: (params) => indexMap[params.row._id],
        valueFormatter: (params) => `#${params.value + 1}`,
      },
      {
        field: 'equityStructure',
        headerName: 'Equity Structure',
        width: 150,
        valueGetter: (params) => params.row.profile?.equityStructures?.map(val => val.replace('Equity', '').trim()).join(', '),
      },
      {
        field: 'minCheckSize',
        headerName: 'Min Check Size',
        width: 80,
        valueGetter: (params) => params.row.profile?.minCheckSize,
        valueFormatter: (params) => params.value != null ? `$${abbreviatedNumber(params.value)}` : undefined,
      },
      {
        field: 'maxCheckSize',
        headerName: 'Max Check Size',
        width: 80,
        valueGetter: (params) => params.row.profile?.maxCheckSize,
        valueFormatter: (params) => params.value != null ? `$${abbreviatedNumber(params.value)}` : undefined,
      },
      {
        field: 'totalCapital',
        headerName: 'Total Capital to be Deployed in Next 12 Months',
        valueGetter: (params) => params.row.profile?.totalCapitalDeployed,
        valueFormatter: (params) => params.value != null ? `$${abbreviatedNumber(params.value)}` : undefined,
      },
      {
        field: 'geographicFocus',
        headerName: 'Geographic Focus',
        width: 200,
        valueGetter: (params) => params.row.profile?.geographicFocus,
      },
      {
        field: 'targetPropertyTypes',
        headerName: 'Target Property Types',
        width: 300,
        valueGetter: (params) => params.row.profile?.targetPropertyTypes?.join(', '),
      },
      {
        field: 'businessPlans',
        headerName: 'Business Plans',
        width: 300,
        valueGetter: (params) => params.row.profile?.businessPlans?.join(', '),
      },
      {
        field: 'returnHurdles',
        headerName: 'Return Hurdles',
        width: 300,
        valueGetter: (params) => params.row.profile?.returnHurdles,
      },
      {
        field: 'investmentHorizons',
        headerName: 'Hold Periods',
        width: 300,
        valueGetter: (params) => params.row.profile?.investmentHorizons,
        valueFormatter: (params) => params.value != null ? params.value.join(', ') : undefined,
      }
    ]);
  }, [indexMap]);

  return (
    <PageContainer>
      <Typography sx={{
        marginBottom: '40px',
        fontWeight: '400',
        fontSize: '28px',
        lineHeight: '34.24px',
      }}>
        {'CREED Equity Investors'}
      </Typography>

      <Box sx={{
        height: 'calc(100% - 200px)'
      }}>
        <DataGridPro
          sx={{
            fontWeight: '400',
            fontSize: '12px',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
            '& .MuiDataGrid-columnHeader': {
              // Forced to use important since overriding inline styles
              height: 'unset !important'
            },
            '& .MuiDataGrid-columnHeaders': {
              // Forced to use important since overriding inline styles
              maxHeight: '168px !important'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          }}
          columns={columns}
          rows={investors ?? []}
          getRowId={r => r._id}
          rowHeight={60}
          checkboxSelection={false}
          rowSelection={false}
          disableColumnSelector
          autoPageSize
          loading={isInvestorDirectoryLoading} />
      </Box>
    </PageContainer>
  );
}
